import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ButtonComponent } from '../button/button.component';
import { BabboeCargoBikeTypes } from '@algemi/accell/shared/babboe-bike-detection';
import { TextInputComponent } from '../text-input/text-input.component';

@Component({
    selector: 'algemi-purchase-date-input',
    standalone: true,
    imports: [CommonModule, RouterModule, ButtonComponent, TextInputComponent],
    templateUrl: './purchase-date-input.component.html',
    styleUrl: './purchase-date-input.component.scss',
})
export class PurchaseDateInputComponent implements OnChanges {
    @Input() dateInputValue: string | undefined = '';
    @Output() dateChanged = new EventEmitter<string>();

    yearInputValue = '';
    monthInputValue = '';
    dayInputValue = '';

    ngOnChanges() {
        this.dayInputValue = this.getDay(this.dateInputValue);
        this.monthInputValue = this.getMonth(this.dateInputValue);
        this.yearInputValue = this.getYear(this.dateInputValue);
    }

    confirmClicked() {
        this.dateChanged.emit(`${this.dayInputValue}-${this.monthInputValue}-${this.yearInputValue}`);
    }

    getDay(automatedInvoicePurchaseDate: string | undefined) {
        if (automatedInvoicePurchaseDate) {
            return automatedInvoicePurchaseDate.split('-')[0];
        }
        return '';
    }

    getMonth(automatedInvoicePurchaseDate: string | undefined) {
        if (automatedInvoicePurchaseDate) {
            return automatedInvoicePurchaseDate.split('-')[1];
        }
        return '';
    }

    getYear(automatedInvoicePurchaseDate: string | undefined) {
        if (automatedInvoicePurchaseDate) {
            return automatedInvoicePurchaseDate.split('-')[2];
        }
        return '';
    }

    reset() {
        this.dateInputValue = '';
        this.ngOnChanges();
        this.confirmClicked();
    }
}
