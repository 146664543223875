import { Currency } from '../enums/currency';

export class CurrencyConversionService {
    private readonly currencyToEuroMap: Record<Currency, number> = {
        [Currency.Eur]: 1,
        [Currency.Dkk]: 0.13,
        [Currency.Pln]: 0.23,
        [Currency.Gbp]: 1.16,
        [Currency.Aud]: 0.61,
        [Currency.Cad]: 0.68,
        [Currency.Usd]: 0.93,
        [Currency.Nzd]: 0.56,
        [Currency.Chf]: 1.04,
        [Currency.Sek]: 0.087,
        [Currency.Nok]: 0.087,
    };
    convertToEur(valueInCurrency: number, currency: Currency | undefined) {
        if (!currency) {
            return undefined;
        }
        return this.currencyToEuroMap[currency] * valueInCurrency;
    }
    convertFromEur(valueInEur: number, currency: Currency | undefined) {
        if (!currency) {
            return undefined;
        }
        return valueInEur / this.currencyToEuroMap[currency];
    }
}
