import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'algemi-toggle',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './toggle.component.html',
    styleUrl: './toggle.component.scss',
})
export class ToggleComponent {
    @Input() trueText = 'True';
    @Input() falseText = 'False';
    @Output() valueChanged = new EventEmitter<boolean>();
    @Input() set value(val: boolean) {
        this._value = val;
    }
    protected _value = false;

    clicked() {
        this._value = !this._value;
        this.valueChanged.emit(this._value);
    }
}
