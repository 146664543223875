@if (showDaySwitcher()){
<algemi-change-day></algemi-change-day>
<algemi-filter-rest-of-world></algemi-filter-rest-of-world>
} @for(bikeCase of cases();track bikeCase.id; let index = $index){
<div
    class="ticket-container"
    (click)="navigate(bikeCase.id)"
    [ngClass]="{
        selected: bikeCase.id === selectedCaseId(),
        analyzed: wasAnalysisPerformed(bikeCase),
        approved: whereAllQualityChecksComplete(bikeCase)
    }"
>
    <b>{{ index + 1 }}</b
    >: {{ bikeCase.id }}
    <div class="flag-container">
        <div
            *ngIf="bikeCase.invoiceBikePriceHumanCheck !== undefined && bikeCase.invoiceBikePriceHumanCheck !== null"
            class="mask check"
        ></div>
        <div
            [ngClass]="{
                unavailable: !bikeCase.bikePhotoDataUrl,
                hasIdentifiedBike: bikeCase.photoIdentifiedBikeModel
            }"
        >
            BP
        </div>
        <div
            [ngClass]="{
                unavailable: !bikeCase.bikePedalPhotoDataUrl,
                hasIdentifiedBike: bikeCase.photoIdentifiedBikeModel
            }"
        >
            BPP
        </div>
        <div
            [ngClass]="{
                unavailable: !bikeCase.invoiceDataUrl,
                hasIdentifiedBike: bikeCase.automatedInvoiceBikeType
            }"
        >
            I
        </div>
        <div
            class="mask robot"
            [ngClass]="{
                unavailable: bikeCase.invoiceBikePriceModel1 === bikeCase.invoiceBikePriceModel2
            }"
        ></div>
        <div
            class="mask second-hand"
            [ngClass]="{
                unavailable: !isSecondHand(bikeCase)
            }"
        ></div>
        <img
            class="usa-flag"
            [ngClass]="{
                unavailable: !isCountryForWhichInvoiceNeedsToBeAnalyzed(bikeCase)
            }"
            [src]="'/assets/usa-flag.svg'"
        />
        <div
            class="mask flag"
            [ngClass]="{
                unavailable: !isFlagged(bikeCase)
            }"
        ></div>
    </div>
</div>
}
