import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ButtonComponent } from '../button/button.component';
import { BabboeCargoBikeIdentificationResult } from '@algemi/accell/shared/babboe-bike-detection';
import { validReasonsForFlagging } from '../flagged-case-browser-page/valid-reasons-for-flagging';

@Component({
    selector: 'algemi-flagged-case-reason-selection',
    standalone: true,
    imports: [CommonModule, RouterModule, ButtonComponent],
    templateUrl: './flagged-case-reason-selection.component.html',
    styleUrl: './flagged-case-reason-selection.component.scss',
})
export class FlaggedCaseReasonSelectionComponent {
    protected options = [
        [
            [validReasonsForFlagging[0]],
            [validReasonsForFlagging[1]],
            [validReasonsForFlagging[2]],
            [validReasonsForFlagging[3]],
            [validReasonsForFlagging[4]],
            [validReasonsForFlagging[5]],
            [validReasonsForFlagging[6]],
            [validReasonsForFlagging[7]],
            [validReasonsForFlagging[8]],
        ],
        [
            [validReasonsForFlagging[9]],
            [validReasonsForFlagging[10]],
            [validReasonsForFlagging[11]],
            [validReasonsForFlagging[12]],
            [validReasonsForFlagging[13]],
            [validReasonsForFlagging[14]],
            [validReasonsForFlagging[15]],
            [validReasonsForFlagging[16]],
            [validReasonsForFlagging[17]],
        ],
    ];

    @Input() selectedReason: string | undefined;
    @Output() reasonClicked = new EventEmitter<string>();

    clicked(reason: string) {
        this.reasonClicked.emit(reason);
    }
}
