import { Country } from '../dtos/salesforce-bike-recall-case';

export class RestOfWorldService {
    isRestOfWorld(country: Country) {
        return (
            country === Country.NewZealand ||
            country === Country.Australia ||
            country === Country.Canada ||
            country === Country.UnitedStates
        );
    }
}
