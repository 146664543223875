<div class="container">
    <algemi-flagged-case-list
        class="case-list"
        [cases]="cases()"
        [selectedCaseId]="caseId()"
        [navigateSlice]="3"
        [showDaySwitcher]="true"
    ></algemi-flagged-case-list>

    <div class="content-container">
        @if(selectedCase()){
        <div #anchor></div>
        <h1 [ngClass]="{ exists: !!selectedCase()!.model, 'not-exists': !selectedCase()!.model }">
            Case ({{ getBikeBestEstimate(selectedCase()!) }}, {{ selectedCase()?.country }})
        </h1>
        <div class="auto-approve-state">{{ shouldAutomaticallyForward ? 'Auto approving!' : '' }}</div>
        <div class="selected-index">
            {{ selectedCaseIndex()! > 0 ? selectedCaseIndex()! + 1 : '' }}
        </div>
        <div class="explanation">
            All yellow tickets have a bike type discrepancy. All grey tickets have a year discrepancy. Both need to be checked.
            Also, we should check all whether the unknown bike status are valid (with bike icon).<br />
            Possible actions:
            <ul>
                <li>'left/right' key: go to the previous/next ticket</li>
                <li>'up/down' key: move to the previous/next element of the page</li>
                <li>'a' key: accept the discrepancy</li>
                <li>'r' key: reset the check status</li>
                <li>'f' key: flag for customer service</li>
                <li>'space' key: start/stop auto approval</li>
                <li>select one of the bike types/dates provided below</li>
            </ul>
            Continue until all tickets are green. The bike icon means the best estimate is bike type 'unknown'. User icon means
            that customer thinks it is a different model.
        </div>
        <div class="identification-overview-container">
            <div class="identification-overview-column">
                <b>Source</b>
                <div>Customer</div>
                <div>Bike id</div>
                <div>Bike photo</div>
                <div *ngIf="selectedCase()?.invoiceDataUrl">Invoice</div>
            </div>
            <div class="identification-overview-column">
                <b>Bike Type</b>
                <div>{{ selectedCase()!.model }}</div>
                <div>-</div>
                <div>
                    {{
                        selectedCase()!.photoIdentifiedBikeModel !== BabboeCargoBikeIdentificationResult.Unknown
                            ? 'Babboe ' + selectedCase()!.photoIdentifiedBikeModel
                            : '?'
                    }}
                </div>
                <div *ngIf="selectedCase()?.invoiceDataUrl">
                    {{
                        selectedCase()!.automatedInvoiceBikeType !== BabboeCargoBikeIdentificationResult.Unknown
                            ? 'Babboe ' + selectedCase()!.automatedInvoiceBikeType
                            : '?'
                    }}
                </div>
            </div>
            <div class="identification-overview-column">
                <b>Date</b>
                <div>{{ selectedCase()!.modelYear }}</div>
                <div>
                    {{
                        selectedCase()!.bikeIdDate
                            ? selectedCase()!.bikeIdDate!.split('-')[2]
                                ? selectedCase()!.bikeIdDate!.split('-')[2]
                                : '-'
                            : '-'
                    }}
                </div>
                <div>-</div>
                <div *ngIf="selectedCase()?.invoiceDataUrl">
                    {{
                        selectedCase()!.automatedInvoicePurchaseDate
                            ? selectedCase()!.automatedInvoicePurchaseDate!.split('-')[2]
                                ? selectedCase()!.automatedInvoicePurchaseDate!.split('-')[2]
                                : '?'
                            : '?'
                    }}
                </div>
            </div>
            <div class="identification-overview-column">
                <br />
                <br />
                <div>({{ selectedCase()!.bikeId }})</div>
            </div>
        </div>
        <algemi-flagged-case-list
            class="case-list-summary"
            [cases]="[selectedCase()!]"
            [selectedCaseId]="selectedCase()!.id"
            [navigateSlice]="3"
        ></algemi-flagged-case-list>
        <algemi-toggle
            [value]="this.selectedCase()!.isApproved ?? false"
            [falseText]="'Approve'"
            [trueText]="'Approved!'"
            (valueChanged)="changeApprovalState($event)"
        ></algemi-toggle>
        <div class="row-container">
            <algemi-text-input
                [value]="this.selectedCase()!.reasonForFlaggingForCustomerService ?? ''"
                (valueChange)="reasonForFlaggingForCustomerService = $event"
            ></algemi-text-input>
            <algemi-button (click)="reasonForFlaggingChanged(reasonForFlaggingForCustomerService)">Save feedback</algemi-button>
        </div>
        <algemi-toggle
            [value]="this.selectedCase()!.isFlaggedForReviewByCustomerService ?? false"
            [falseText]="'Flag for Customer Service'"
            [trueText]="'Flagged for Customer Service!'"
            (valueChanged)="changeCustomerServiceFlag($event)"
            >Flag for Customer Service</algemi-toggle
        >
        <algemi-flagged-case-reason-selection
            (reasonClicked)="reasonForFlaggingChangedBySelection($event)"
            [selectedReason]="selectedCase()?.reasonForFlaggingForCustomerService"
        ></algemi-flagged-case-reason-selection>

        <!--        <div class="row-container">-->
        <!--            @for(reason of validReasonsForFlagging;track reason){-->
        <!--            <algemi-button (click)="reasonForFlaggingChanged(reasonForFlaggingForCustomerService)">{{ reason }}</algemi-button>-->
        <!--            }-->
        <!--        </div>-->
        <div #anchor></div>
        <h3 [ngClass]="{ exists: !!selectedCase()!.bikePhotoDataUrl, 'not-exists': !selectedCase()!.bikePhotoDataUrl }">
            Bike photo ({{ selectedCase()!.automatedIdentifiedBikeModel }}
            {{ selectedCase()!.automatedIsMountainModel ? 'Mountain' : '' }})
        </h3>
        <div class="image-container">
            <algemi-ocr-image-component
                [src]="selectedCase()!.bikePhotoDataUrl"
                [ocrText]="selectedCase()!.bikePhotoRecognizedText"
                type="img"
            ></algemi-ocr-image-component>
            <algemi-ocr-image-component
                [src]="selectedCase()!.bikePedalPhotoDataUrl"
                [ocrText]="selectedCase()!.bikePedalPhotoRecognizedText"
                type="img"
            ></algemi-ocr-image-component>
        </div>
        <algemi-bike-type-selection
            (typeClicked)="photoBikeTypeChanged($event)"
            [selectedType]="selectedCase()?.photoIdentifiedBikeModel"
        ></algemi-bike-type-selection>

        <div #anchor></div>
        <h3 [ngClass]="{ exists: !!selectedCase()!.invoiceDataUrl, 'not-exists': !selectedCase()!.invoiceDataUrl }">
            Invoice ({{ selectedCase()!.automatedInvoiceBikeType }}, {{ selectedCase()!.automatedInvoicePurchaseDate }})
        </h3>
        <algemi-purchase-date-input
            *ngIf="selectedCase()?.invoiceDataUrl"
            [dateInputValue]="selectedCase()?.automatedInvoicePurchaseDate"
            (dateChanged)="invoiceDateChanged($event)"
        ></algemi-purchase-date-input>
        <algemi-ocr-image-component
            *ngIf="selectedCase()?.invoiceDataUrl"
            [src]="selectedCase()!.invoiceDataUrl"
            [ocrText]="selectedCase()!.invoiceRecognizedText"
            [type]="invoiceFileType()"
        ></algemi-ocr-image-component>
        <div #anchor></div>
        <algemi-bike-type-selection
            *ngIf="selectedCase()?.invoiceDataUrl"
            (typeClicked)="invoiceBikeTypeChanged($event)"
            [selectedType]="selectedCase()?.automatedInvoiceBikeType"
        ></algemi-bike-type-selection>
        } @else{
        <b>Please select a ticket</b>
        }
    </div>
</div>
