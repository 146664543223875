import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ButtonComponent } from '../button/button.component';
import { BabboeCargoBikeIdentificationResult } from '@algemi/accell/shared/babboe-bike-detection';

@Component({
    selector: 'algemi-bike-type-selection',
    standalone: true,
    imports: [CommonModule, RouterModule, ButtonComponent],
    templateUrl: './bike-type-selection.component.html',
    styleUrl: './bike-type-selection.component.scss',
})
export class BikeTypeSelectionComponent {
    protected options = [
        [
            [BabboeCargoBikeIdentificationResult.Big, BabboeCargoBikeIdentificationResult.BigE],
            [
                BabboeCargoBikeIdentificationResult.Curve,
                BabboeCargoBikeIdentificationResult.CurveE,
                BabboeCargoBikeIdentificationResult.CurveMountain,
            ],
            [BabboeCargoBikeIdentificationResult.FlowE, BabboeCargoBikeIdentificationResult.FlowMountain],
            [
                BabboeCargoBikeIdentificationResult.Mini,
                BabboeCargoBikeIdentificationResult.MiniE,
                BabboeCargoBikeIdentificationResult.MiniMountain,
            ],
            [BabboeCargoBikeIdentificationResult.SlimMountain],
            [
                BabboeCargoBikeIdentificationResult.City,
                BabboeCargoBikeIdentificationResult.CityE,
                BabboeCargoBikeIdentificationResult.CityMountain,
            ],
            [
                BabboeCargoBikeIdentificationResult.Go,
                BabboeCargoBikeIdentificationResult.GoE,
                BabboeCargoBikeIdentificationResult.GoMountain,
            ],
        ],
        [
            [BabboeCargoBikeIdentificationResult.CarveE, BabboeCargoBikeIdentificationResult.CarveMountain],
            [BabboeCargoBikeIdentificationResult.MaxE],
            [BabboeCargoBikeIdentificationResult.Dog, BabboeCargoBikeIdentificationResult.DogE],
            [
                BabboeCargoBikeIdentificationResult.ProBike,
                BabboeCargoBikeIdentificationResult.ProBikeE,
                BabboeCargoBikeIdentificationResult.ProBikeMidMotor,
            ],
            [BabboeCargoBikeIdentificationResult.ProTrike, BabboeCargoBikeIdentificationResult.ProTrikeE],
            [BabboeCargoBikeIdentificationResult.ProTrikeXlMidMotor],
            [BabboeCargoBikeIdentificationResult.Transporter, BabboeCargoBikeIdentificationResult.TransporterE],
            [BabboeCargoBikeIdentificationResult.Unknown],
        ],
    ];

    @Input() selectedType: BabboeCargoBikeIdentificationResult | undefined;
    @Output() typeClicked = new EventEmitter<BabboeCargoBikeIdentificationResult>();

    clicked(bikeType: BabboeCargoBikeIdentificationResult) {
        this.typeClicked.emit(bikeType);
    }
}
