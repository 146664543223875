<div class="container">
    <algemi-invoice-bike-identification-list
        class="case-list"
        [cases]="cases()"
        [selectedCaseId]="caseId()"
        [navigateSlice]="3"
        [showDaySwitcher]="true"
    ></algemi-invoice-bike-identification-list>
    <div class="explanation">
        Check whether the bike can be identified by the invoice. The item becomes green when the bike has been manually
        checked.<br />
        Possible actions:
        <ul>
            <li>'left/right' key: go to the previous/next ticket</li>
            <li>'up/down' key: go to the top/bottom of the page</li>
            <li>'a' key: accept the bike type provided in the title</li>
            <li>'u' key: set the bike type to 'unknown'</li>
            <li>'r' key: reset the check status</li>
            <li>'f' key: flag for customer service</li>
            <li>select one of the bike types provided below</li>
        </ul>
        Continue until all tickets are green. The bike icon means the best estimate is bike type 'unknown'. User icon means that
        customer thinks it is a different model. Grey list item means that the model classified it as a bike type that does not
        exist
    </div>

    <div class="content-container">
        @if(selectedCase()){
        <div #anchor></div>
        <h1 [ngClass]="{ exists: !!selectedCase()!.model, 'not-exists': !selectedCase()!.model }">
            {{ getBikeBestEstimate(selectedCase()!) }}
        </h1>
        <!--        {{ selectedCase()?.automatedInvoiceBikeType }}-->
        <algemi-invoice-bike-identification-list
            class="case-list-summary"
            [cases]="[selectedCase()!]"
            [selectedCaseId]="selectedCase()!.id"
            [navigateSlice]="3"
        ></algemi-invoice-bike-identification-list>
        <algemi-toggle
            [value]="this.selectedCase()!.isFlaggedForReviewByCustomerService ?? false"
            [falseText]="'Flag for Customer Service'"
            [trueText]="'Flagged for Customer Service!'"
            (valueChanged)="changeCustomerServiceFlag($event)"
            >Flag for Customer Service</algemi-toggle
        >
        Customer thinks it is: {{ selectedCase()!.model }}<br />
        Photo identification has found: {{ selectedCase()!.photoIdentifiedBikeModel }}<br />
        Invoice identification: {{ selectedCase()!.automatedInvoiceBikeType }}<br />
        <algemi-ocr-image-component
            *ngIf="selectedCase()?.invoiceDataUrl"
            [src]="selectedCase()!.invoiceDataUrl"
            [ocrText]="selectedCase()!.invoiceRecognizedText"
            [type]="invoiceFileType()"
        ></algemi-ocr-image-component>
        <div #anchor></div>
        <algemi-bike-type-selection
            *ngIf="selectedCase()?.invoiceDataUrl"
            (typeClicked)="invoiceBikeTypeChanged($event)"
            [selectedType]="selectedCase()?.automatedInvoiceBikeType"
        ></algemi-bike-type-selection>
        } @else{
        <b>Please select a ticket</b>
        }
    </div>
</div>
