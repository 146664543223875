import { Component, EventEmitter, input, Input, OnChanges, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ButtonComponent } from '../button/button.component';
import { TextInputComponent } from '../text-input/text-input.component';
import { Currency } from '@algemi/accell/shared/babboe-bike-detection';
import { AccessoryPriceComponent } from '../accessory-price/accessory-price.component';

@Component({
    selector: 'algemi-price-input',
    standalone: true,
    imports: [CommonModule, RouterModule, ButtonComponent, TextInputComponent, AccessoryPriceComponent],
    templateUrl: './price-input.component.html',
    styleUrl: './price-input.component.scss',
})
export class PriceInputComponent implements OnChanges {
    @Input() price: number | undefined = undefined;
    @Input() priceModel1: number | undefined = undefined;
    @Input() priceModel2: number | undefined = undefined;
    @Input() vatPercentage: number | undefined = undefined;
    @Input() currency: Currency | undefined = undefined;
    accessoryPrice = input.required<number | undefined>();
    isRestOfWorld = input.required<boolean>();
    @Output() priceChanged = new EventEmitter<number | 'Unknown'>();
    @Output() accessoryPriceChanged = new EventEmitter<number>();

    protected allCurrencies: Currency[] = Object.values(Currency);

    priceValue = '';
    @Output() currencyChanged = new EventEmitter<Currency>();
    ngOnChanges() {
        this.priceValue = this.price ? `${this.price}` : '';
    }

    confirmClicked() {
        this.priceChanged.emit(parseFloat(this.priceValue.replace(',', '.')));
    }

    confirmedPrice(price: number | undefined) {
        if (price) {
            this.priceChanged.emit(price);
        }
    }

    isValidPrice(price: string): boolean {
        return typeof this.toNumber(price) === 'number';
    }

    protected toNumber(price: string): number | undefined {
        if (!price) {
            return undefined;
        }
        const floatVal = parseFloat(price.replace(',', '.'));
        if (isNaN(floatVal)) {
            return undefined;
        }
        return floatVal;
    }

    protected formatPrice(price: string): string {
        const num = this.toNumber(price);
        return this.formatPriceNumber(num);
    }

    protected formatPriceNumber(price: number | undefined): string {
        const currencyToSymbolMap = {
            [Currency.Eur]: '€',
            [Currency.Chf]: 'CHF',
            [Currency.Gbp]: '£',
            [Currency.Dkk]: 'DKK',
            [Currency.Sek]: 'SEK',
            [Currency.Nok]: 'NOK',
            [Currency.Cad]: 'CAD',
            [Currency.Usd]: '$',
            [Currency.Aud]: 'AUD',
            [Currency.Nzd]: 'NZD',
            [Currency.Pln]: 'PLN',
        };
        if (!price) {
            return 'Enter a price';
        }
        return `Confirm ${currencyToSymbolMap[this.currency ?? Currency.Eur]} ${price.toFixed(2)}`;
    }

    protected readonly Currency = Currency;
}
