export enum BabboeCargoBikeModels {
    Big = 'Big',
    Curve = 'Curve',
    Flow = 'Flow',
    Mini = 'Mini',
    Slim = 'Slim',
    City = 'City',
    Go = 'Go',
    Carve = 'Carve',
    Max = 'Max',
    Dog = 'Dog',
    ProBike = 'ProBike',
    ProTrike = 'ProTrike',
    ProTrikeXl = 'ProTrikeXl',
    Transporter = 'Transporter',
}
