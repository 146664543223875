import { Injectable } from '@angular/core';
import { mockBikeData } from '../data/mock-data';
import {
    BabboeCargoBikeIdentificationResult,
    BabboeCargoBikeModels,
    BabboeCargoBikeTypes,
    BikeRecallCaseDto,
    Currency,
} from '@algemi/accell/shared/babboe-bike-detection';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BackendService {
    private _bikeCasesPerBatch: { [batch: string]: BikeRecallCaseDto[] } = {};
    constructor(private readonly client: HttpClient) {}

    async getBikeCases(batchName: string) {
        if (!Object.keys(this._bikeCasesPerBatch).includes(batchName)) {
            this._bikeCasesPerBatch[batchName] = await firstValueFrom(
                this.client.get<BikeRecallCaseDto[]>(`${environment.backendBaseUrl}/quality-frontend/${batchName}`),
            );
        }
        return this._bikeCasesPerBatch[batchName];
    }
    async getAdditionalDataFlowBikeCases(batchName: string) {
        const key = 'additional_flow' + batchName;
        if (!Object.keys(this._bikeCasesPerBatch).includes(key)) {
            this._bikeCasesPerBatch[key] = await firstValueFrom(
                this.client.get<BikeRecallCaseDto[]>(`${environment.backendBaseUrl}/additional-data-flow/${batchName}`),
            );
        }
        return this._bikeCasesPerBatch[key];
    }
    async getAdditionalDataFlowBikeCase(batchName: string, id: string): Promise<BikeRecallCaseDto> {
        return await firstValueFrom(
            this.client.get<BikeRecallCaseDto>(`${environment.backendBaseUrl}/additional-data-flow/${batchName}/${id}`),
        );
    }

    async getCaseIds(batchName: string) {
        const caseDtos = await this.getBikeCases(batchName);
        return caseDtos.map((caseDto) => ({ id: caseDto.id }));
    }
    async getCase(batchName: string, id: string): Promise<BikeRecallCaseDto> {
        return await firstValueFrom(
            this.client.get<BikeRecallCaseDto>(`${environment.backendBaseUrl}/quality-frontend/${batchName}/${id}`),
        );
    }
    async getBikesOfModel(batchName: string, model: BabboeCargoBikeModels | undefined): Promise<BikeRecallCaseDto[]> {
        const cases = await this.getBikeCases(batchName);
        return cases.filter((d) => d.automatedIdentifiedBikeModel === model);
    }
    async getBikesOfType(batchName: string, type: BabboeCargoBikeTypes | undefined) {
        const cases = await this.getBikeCases(batchName);
        return cases.filter((d) => d.automatedInvoiceBikeType === type);
    }
    async getBikes(batchName: string) {
        return await this.getBikeCases(batchName);
    }
    getBikesWithIsMountain(isMountain: boolean) {
        return mockBikeData.filter((d) => d.automatedIsMountainModel === isMountain);
    }
    private getAllBikeData() {
        return mockBikeData;
    }

    async updateTicket(batchName: string, bikeIdentificationTicket: BikeRecallCaseDto) {
        await firstValueFrom(
            this.client.post(`${environment.backendBaseUrl}/quality-frontend/${batchName}/${bikeIdentificationTicket.id}`, {
                humanIdentifiedBikeModel: bikeIdentificationTicket.humanIdentifiedBikeModel,
                humanIsMountainModel: bikeIdentificationTicket.humanIsMountainModel,
                humanIdentifiedInvoiceBikeType: bikeIdentificationTicket.humanIdentifiedInvoiceBikeType,
                humanIdentifiedInvoicePurchaseDate: bikeIdentificationTicket.humanIdentifiedInvoicePurchaseDate,
            } as BikeRecallCaseDto),
        );
    }

    async updateCase(
        batchName: string,
        caseId: string,
        bikeIdentificationTicket: {
            photoIdentifiedBikeModel?: BabboeCargoBikeIdentificationResult;
            invoiceBikeType?: BabboeCargoBikeIdentificationResult;
            invoiceDate?: string;
            bikeIdDate?: string;
            isApproved?: boolean;
            isFlaggedForReviewByCustomerService?: boolean;
            isFlaggedAsSecondHand?: boolean;
            isFlaggedAsOverrulingCustomer?: boolean;
            arePhotosValid?: boolean;
            manuallyTaggedBestEstimateBikeType?: BabboeCargoBikeIdentificationResult;
            invoiceBikePriceHumanCheck?: number | 'Unknown';
            isBikePhotoManuallyChecked?: boolean;
            isInvoiceBikeManuallyChecked?: boolean;
            currency?: Currency;
            reasonForFlaggingForCustomerService?: string;
            accessoryPrice?: number;
        },
    ) {
        await firstValueFrom(
            this.client.post(`${environment.backendBaseUrl}/quality-frontend/${batchName}/${caseId}`, bikeIdentificationTicket),
        );
    }
    async additionalDataFlowUpdateCase(
        batchName: string,
        caseId: string,
        bikeIdentificationTicket: {
            photoIdentifiedBikeModel?: BabboeCargoBikeIdentificationResult;
            invoiceBikeType?: BabboeCargoBikeIdentificationResult;
            emailAttachmentIdentifiedBikeModel?: BabboeCargoBikeIdentificationResult;
            invoiceDate?: string;
            bikeIdDate?: string;
            isApproved?: boolean;
            isAdditionalDataFlowApproved?: Record<string, boolean>;
            isFlaggedForReviewByCustomerService?: boolean;
            isFlaggedAsSecondHand?: boolean;
            isFlaggedAsOverrulingCustomer?: boolean;
            arePhotosValid?: boolean;
            manuallyTaggedBestEstimateBikeType?: BabboeCargoBikeIdentificationResult;
            invoiceBikePriceHumanCheck?: number | 'Unknown';
            isBikePhotoManuallyChecked?: boolean;
            isInvoiceBikeManuallyChecked?: boolean;
            currency?: Currency;
            reasonForFlaggingForCustomerService?: string;
            accessoryPrice?: number;
            isFlaggedAsUnusablePhotos?: boolean;
            isFlaggedAsNotOriginalElectric?: boolean;
        },
    ) {
        await firstValueFrom(
            this.client.post(
                `${environment.backendBaseUrl}/additional-data-flow/${batchName}/${caseId}`,
                bikeIdentificationTicket,
            ),
        );
    }
}
