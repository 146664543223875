export enum RecallInformationCategory {
    Unknown = 'Option 5 - Unknown',
    Refund = 'Option 3 - Refund',
}

export enum RecallPurchaseMethodBike {
    Dealer = 'Dealer',
    Lease = 'Lease',
    Website = 'Website',
    PrivateSecondHand = 'Private (second hand)',
    null = 'Unknown',
}

export enum NumberOfWheels {
    Two = 2,
    Three = 3,
    Unknown = -1,
}
export enum RecallStatus {
    RequestReceived = 'Request received',
}
export enum SalesForceBoolean {
    False = 0,
    True = 1,
}
export enum BabboeBikeModel {
    BabboeCurveMountain = 'Babboe Curve Mountain',
    BabboeFlowMountain = 'Babboe Flow Mountain',
    BabboeBigE = 'Babboe Big-E',
    BabboeDogE = 'Babboe Dog-E',
    BabboeCityE = 'Babboe City-E',
    BabboeGoE = 'Babboe Go-E',
    BabboeGoMountain = 'Babboe Go Mountain',
    BabboeFlowE = 'Babboe Flow-E',
    BabboeCarveMountain = 'Babboe Carve Mountain',
    BabboeCurveE = 'Babboe Curve-E',
    BabboeBig = 'Babboe Big',
    BabboeProTrikeE = 'Babboe Pro Trike-E',
    BabboeCurve = 'Babboe Curve',
    BabboeMaxE = 'Babboe Max-E',
    BabboeCityMountain = 'Babboe City Mountain',
    BabboeMiniE = 'Babboe Mini-E',
    BabboeDog = 'Babboe Dog',
    BabboeProBike = 'Babboe Pro Bike',
    BabboeProTrikeXL = 'Babboe Pro Trike XL',
    BabboeProBikeMidmotor = 'Babboe Pro Bike Midmotor',
    BabboeCarveE = 'Babboe Carve-E',
    BabboeProTrike = 'Babboe Pro Trike',
    BabboeSlimMountain = 'Babboe Slim Mountain',
    BabboeCity = 'Babboe City',
    BabboeMiniMountain = 'Babboe Mini Mountain',
    BabboeProBikeE = 'Babboe Pro Bike-E',
    BabboeGo = 'Babboe Go',
    BabboeMini = 'Babboe Mini',
    BabboeTransporterE = 'Babboe Transporter-E',
    BabboeTransporter = 'Babboe Transporter',
    Unknown = 'Unknown',
}

export enum BikeAge {
    Unknown = 'Unknown',
    Before2004 = 'Before 2004',
    Year1998 = 1998,
    Year1999 = 1999,
    Year2000 = 2000,
    Year2001 = 2001,
    Year2002 = 2002,
    Year2003 = 2003,
    Year2004 = 2004,
    Year2005 = 2005,
    Year2006 = 2006,
    Year2007 = 2007,
    Year2008 = 2008,
    Year2009 = 2009,
    Year2010 = 2010,
    Year2011 = 2011,
    Year2012 = 2012,
    Year2013 = 2013,
    Year2014 = 2014,
    Year2015 = 2015,
    Year2016 = 2016,
    Year2017 = 2017,
    Year2018 = 2018,
    Year2019 = 2019,
    Year2020 = 2020,
    Year2021 = 2021,
    Year2022 = 2022,
    Year2023_2024 = '2024/2023',
}

export enum BatteryLocation {
    Unknown = 'Unknown',
    InTheCargoArea = 'In the Cargo area', //Mountain
    UnderneathTheLuggageCarrier = 'Underneath the luggage carrier', //E
    BehindTheCargoArea = 'Behind the Cargo Area', //Pro trike XL midmotor
    NotApplicable = 'Not Applicable',
}
export enum Brand {
    Babboe = 'Babboe',
    BabboePro = 'BabboePro',
    BabboePro2 = 'Babboe Pro',
    CentaurCargoBikes = 'Centaur Cargo bikes',
}
export enum Country {
    Germany = 'Germany',
    Netherlands = 'Netherlands',
    France = 'France',
    Austria = 'Austria',
    Denmark = 'Denmark',
    UnitedKingdom = 'United Kingdom',
    Belgium = 'Belgium',
    Ireland = 'Ireland',
    Finland = 'Finland',
    UnitedStates = 'United States',
    Canada = 'Canada',
    Australia = 'Australia',
    NewZealand = 'New Zealand',
    Sweden = 'Sweden',
    Norway = 'Norway',
    Hungary = 'Hungary',
    Luxembourg = 'Luxembourg',
    Switzerland = 'Switzerland',
    Spain = 'Spain',
    Poland = 'Poland',
}
export interface SalesforceBikeRecallCase {
    caseId: string;
    numberOfWheels: NumberOfWheels;
    recallInformationCategory: RecallInformationCategory;
    recallPurchaseMethodBike: RecallPurchaseMethodBike;
    recallStatus: RecallStatus;
    bikeId: string;
    electricBike: SalesForceBoolean;
    model: BabboeBikeModel;
    bikeAge: BikeAge;
    locationBattery: BatteryLocation;
    brand: Brand;
    country: Country;
    secondHand: SalesForceBoolean;
}
