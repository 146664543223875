<div class="container">
    <algemi-bike-photo-list
        class="case-list"
        [cases]="cases()"
        [selectedCaseId]="caseId()"
        [navigateSlice]="3"
        [showDaySwitcher]="true"
    ></algemi-bike-photo-list>

    <div class="auto-approve-state">{{ shouldAutomaticallyForward ? 'Auto approving!' : '' }}</div>
    <div class="selected-index">
        {{ selectedCaseIndex()! > 0 ? selectedCaseIndex()! + 1 : '' }}
    </div>
    <div class="explanation">
        Check whether the bike can be identified by the photo. The item becomes green when the bike has been manually checked.<br />
        Possible actions:
        <ul>
            <li>'left/right' key: go to the previous/next ticket</li>
            <li>'up/down' key: go to the top/bottom of the page</li>
            <li>'a' key: accept the bike type provided in the title</li>
            <li>'u' key: set the bike type to 'unknown'</li>
            <li>'r' key: reset the check status</li>
            <li>'f' key: flag for customer service</li>
            <li>'space' key: start/stop auto approval</li>
            <li>select one of the bike types provided below</li>
        </ul>
        Continue until all tickets are green. The bike icon means the best estimate is bike type 'unknown'. User icon means that
        customer thinks it is a different model.
    </div>

    <div class="content-container">
        @if(selectedCase()){
        <div #anchor></div>
        <h1 [ngClass]="{ exists: !!selectedCase()!.model, 'not-exists': !selectedCase()!.model }">
            {{ getApprovalBikeCase(selectedCase()!) }}
        </h1>
        <algemi-bike-photo-list
            class="case-list-summary"
            [cases]="[selectedCase()!]"
            [selectedCaseId]="selectedCase()!.id"
            [navigateSlice]="3"
        ></algemi-bike-photo-list>
        <algemi-toggle
            [value]="this.selectedCase()!.isFlaggedForReviewByCustomerService ?? false"
            [falseText]="'Flag for Customer Service'"
            [trueText]="'Flagged for Customer Service!'"
            (valueChanged)="changeCustomerServiceFlag($event)"
            >Flag for Customer Service</algemi-toggle
        >

        <div class="row-container">
            <algemi-text-input
                [value]="this.selectedCase()!.reasonForFlaggingForCustomerService ?? ''"
                (valueChange)="reasonForFlaggingForCustomerService = $event"
            ></algemi-text-input>
            <algemi-button (click)="reasonForFlaggingChanged(reasonForFlaggingForCustomerService)">Save feedback</algemi-button>
        </div>
        Customer thinks it is: {{ selectedCase()!.model }}
        <div #anchor></div>
        <div class="image-container">
            <algemi-ocr-image-component
                [src]="selectedCase()!.bikePhotoDataUrl"
                [ocrText]="selectedCase()!.bikePhotoRecognizedText"
                type="img"
            ></algemi-ocr-image-component>
            <algemi-ocr-image-component
                [src]="selectedCase()!.bikePedalPhotoDataUrl"
                [ocrText]="selectedCase()!.bikePedalPhotoRecognizedText"
                type="img"
            ></algemi-ocr-image-component>
        </div>
        <algemi-bike-type-selection
            (typeClicked)="photoBikeTypeChanged($event)"
            [selectedType]="selectedCase()?.photoIdentifiedBikeModel"
        ></algemi-bike-type-selection>
        } @else{
        <b>Please select a ticket</b>
        }
    </div>
</div>
