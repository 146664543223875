<div class="container">
    @for (bikeTypeOptionsColumn of options; track options) {
    <div class="column">
        @for (bikeTypeOptionsRow of bikeTypeOptionsColumn; track options) {
        <div class="row">
            @for (bikeType of bikeTypeOptionsRow; track options) {
            <algemi-button [ngClass]="{ selected: bikeType === selectedType }" (click)="clicked(bikeType)">{{
                bikeType
            }}</algemi-button>
            }
        </div>
        }
    </div>
    }
</div>
