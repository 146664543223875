import { BabboeCargoBikeIdentificationResult } from '../enums/babboe-cargo-bike-identification-result';

export class BestEstimateService {
    public getExpectedBikeType(
        customerBikeResult: BabboeCargoBikeIdentificationResult,
        photoBikeResult: BabboeCargoBikeIdentificationResult,
        invoiceBikeResult: BabboeCargoBikeIdentificationResult,
    ): BabboeCargoBikeIdentificationResult {
        // * Als de invoice hetzelfde zegt als de klant-->invoice
        //     * Als de invoice en foto hetzelfde zeggen->invoice
        //         * Als de foto en klant hetzelfde zeggen, maar er is geen invoice of deze is unknown-> ok
        //         * Als de invoice iets expliciets anders zegt (ander type)--> flag en unknown
        //     * Anders ook unknown
        if (invoiceBikeResult !== BabboeCargoBikeIdentificationResult.Unknown && invoiceBikeResult === customerBikeResult) {
            return invoiceBikeResult;
        }
        if (invoiceBikeResult === photoBikeResult && invoiceBikeResult !== undefined) {
            return invoiceBikeResult;
        }
        if (
            photoBikeResult === customerBikeResult &&
            (!invoiceBikeResult || invoiceBikeResult === BabboeCargoBikeIdentificationResult.Unknown)
        ) {
            return photoBikeResult;
        }
        if (
            photoBikeResult &&
            photoBikeResult !== BabboeCargoBikeIdentificationResult.Unknown &&
            (!invoiceBikeResult || invoiceBikeResult === BabboeCargoBikeIdentificationResult.Unknown)
        ) {
            return photoBikeResult;
        }
        return BabboeCargoBikeIdentificationResult.Unknown;
    }
    public getFinalExpectedBikeType(
        customerBikeResult: BabboeCargoBikeIdentificationResult,
        photoBikeResult: BabboeCargoBikeIdentificationResult,
        invoiceBikeResult: BabboeCargoBikeIdentificationResult,
    ) {
        if (photoBikeResult && photoBikeResult !== BabboeCargoBikeIdentificationResult.Unknown) {
            //if photo identification
            if (photoBikeResult === invoiceBikeResult) {
                //if invoice has been identified and matches the photo
                return photoBikeResult;
            } else {
                if (invoiceBikeResult && invoiceBikeResult !== BabboeCargoBikeIdentificationResult.Unknown) {
                    //if the invoice could be identified
                    return 'Mismatch';
                } else {
                    //if the invoice could not be identified
                    return photoBikeResult;
                }
            }
        } else {
            //if photos could not be identified
            if (invoiceBikeResult && invoiceBikeResult !== BabboeCargoBikeIdentificationResult.Unknown) {
                //if the invoice could be identified
                return invoiceBikeResult;
            } else {
                //if the invoice could not be identified
                return 'Unidentified';
            }
        }
    }
    public getExpectedYear(
        customerYear: number | '2024/2023' | 'Unknown',
        bikeIdYear: number | 'Unknown',
        invoiceYear: number | 'Unknown',
    ): number | 'Unknown' {
        // * Als invoice en klant+1 matchen-->invoice
        // * Als frame id+1 jaartal en invoice matchen of invoice-->invoice
        // * Als afwijking groter is --> flag for (year discrepancy)
        // * Invoice-->invoice
        // * geen invoice-->unknown

        const customerYearNumber = this.dateStringToNumberOrUndefined(customerYear);
        const bikeIdYearNumber = this.dateStringToNumberOrUndefined(bikeIdYear);
        const invoiceYearNumber = this.dateStringToNumberOrUndefined(invoiceYear);

        if (invoiceYearNumber) {
            return invoiceYearNumber;
        } else {
            if (
                bikeIdYearNumber &&
                customerYearNumber &&
                (bikeIdYearNumber === customerYearNumber || bikeIdYearNumber + 1 === customerYearNumber)
            ) {
                return customerYearNumber;
            }
        }

        return 'Unknown';
    }
    flagModelDiscrepancy(
        customerBikeResult: BabboeCargoBikeIdentificationResult,
        photoBikeResult: BabboeCargoBikeIdentificationResult,
        invoiceBikeResult: BabboeCargoBikeIdentificationResult,
    ) {
        if (invoiceBikeResult !== undefined && invoiceBikeResult !== BabboeCargoBikeIdentificationResult.Unknown) {
            if (customerBikeResult === photoBikeResult && invoiceBikeResult !== photoBikeResult) {
                return true;
            }
        }
        return false;
    }

    flagYearDiscrepancy(
        customerYear: number | '2024/2023' | 'Unknown',
        bikeIdYear: number | 'Unknown',
        invoiceYear: number | 'Unknown',
    ) {
        const customerYearNumber = this.dateStringToNumberOrUndefined(customerYear);
        const bikeIdYearNumber = this.dateStringToNumberOrUndefined(bikeIdYear);
        const invoiceYearNumber = this.dateStringToNumberOrUndefined(invoiceYear);

        if (invoiceYearNumber) {
            if (
                customerYearNumber &&
                (invoiceYearNumber - customerYearNumber > 1 || invoiceYearNumber - customerYearNumber < 0)
            ) {
                return true;
            }
            if (bikeIdYearNumber && (invoiceYearNumber - bikeIdYearNumber > 1 || invoiceYearNumber - bikeIdYearNumber < 0)) {
                return true;
            }
        }
        return false;
    }

    private dateStringToNumberOrUndefined(customerYear: number | '2024/2023' | 'Unknown' | 'Before 2004') {
        if (typeof customerYear === 'string') {
            switch (customerYear) {
                case '2024/2023':
                    return 2023;
                case 'Unknown':
                    return undefined;
                case 'Before 2004':
                    return 2004;
                default:
                    throw new Error(`Invalid customer year '${customerYear}'`);
            }
        } else if (typeof customerYear === 'number') {
            return customerYear > 2000 ? customerYear : undefined;
        } else {
            throw new Error(`Invalid customer year '${customerYear}'`);
        }
    }

    getExpectedPrice(
        model1Price: number | undefined,
        model2Price: number | undefined,
        humanCheckPrice: number | undefined | 'Unknown',
    ) {
        if (!humanCheckPrice) {
            if (model1Price) {
                if (model2Price) {
                    if (model1Price === model2Price) {
                        return model1Price;
                    }
                } else {
                    return model1Price;
                }
            } else {
                if (model2Price) {
                    return model2Price;
                }
            }
        } else {
            return humanCheckPrice;
        }
        return undefined;
    }
}
