import { BabboeCargoBikeIdentificationResult } from '../enums/babboe-cargo-bike-identification-result';
import { BabboeCargoBikeTypes } from '../enums/babboe-cargo-bike-types';
import { BestEstimateService } from './best-estimate.service';
import { BikeYearService } from './bike-year.service';
import { ModelIdentificationResultService } from './model-identification-result.service';
import { BabboeBikeModel, Country } from '../dtos/salesforce-bike-recall-case';
import { RestOfWorldService } from './rest-of-world.service';

type BikeCaseInput = {
    bikeId: string | number;
    bikeIdDate?: string;
    invoiceFileType: 'pfd' | 'img' | undefined;
    customerModel: BabboeBikeModel;
    photoIdentifiedBikeModel?: BabboeCargoBikeIdentificationResult;
    automatedInvoiceBikeType?: BabboeCargoBikeIdentificationResult;
    customerYear: number;
    automatedInvoicePurchaseDate?: string;
    country: Country;
};

export class InvoiceAnalysisFilterService {
    constructor(
        private readonly bestEstimateService: BestEstimateService,
        private readonly modelIdentificationResultService: ModelIdentificationResultService,
        private readonly bikeYearService: BikeYearService,
        private readonly restOfWorldService: RestOfWorldService,
    ) {}
    doesCaseInvoiceNeedToBeAnalyzedForPrice(bikeCase: BikeCaseInput, shouldNotAnalyzeCasesWithoutInvoice = true) {
        if (shouldNotAnalyzeCasesWithoutInvoice && !bikeCase.invoiceFileType) {
            return false;
        }
        const isCountryForWhichInvoiceNeedsToBeChecked = this.restOfWorldService.isRestOfWorld(bikeCase.country);

        const isBikeThatNeedsToBeChecked = [
            BabboeCargoBikeIdentificationResult.City,
            BabboeCargoBikeIdentificationResult.CityE,
            BabboeCargoBikeIdentificationResult.Mini,
            BabboeCargoBikeIdentificationResult.MiniE,
            BabboeCargoBikeIdentificationResult.CityMountain,
            BabboeCargoBikeIdentificationResult.MiniMountain,
            BabboeCargoBikeIdentificationResult.Transporter,
            BabboeCargoBikeIdentificationResult.TransporterE,
            BabboeCargoBikeIdentificationResult.ProTrike,
            BabboeCargoBikeIdentificationResult.ProTrikeE,
            BabboeCargoBikeIdentificationResult.ProTrikeXlMidMotor,
            BabboeCargoBikeIdentificationResult.ProBike,
            BabboeCargoBikeIdentificationResult.ProBikeE,
            BabboeCargoBikeIdentificationResult.ProBikeMidMotor,
        ].includes(this.getBikeBestEstimate(bikeCase));
        if (isBikeThatNeedsToBeChecked || isCountryForWhichInvoiceNeedsToBeChecked) {
            return true;
        }
        return false;
    }
    getBikeBestEstimate(bikeCase: BikeCaseInput) {
        const customerModel = this.modelIdentificationResultService.fromCustomerModel(bikeCase.customerModel);
        const photoBikeModel = this.modelIdentificationResultService.fromBikeType(
            bikeCase.photoIdentifiedBikeModel as never as BabboeCargoBikeTypes,
        );
        const invoiceModel = this.modelIdentificationResultService.fromBikeType(
            bikeCase.automatedInvoiceBikeType as never as BabboeCargoBikeTypes,
        );
        return this.bestEstimateService.getExpectedBikeType(customerModel, photoBikeModel, invoiceModel);
    }

    protected getYearBestEstimate(bikeCase: BikeCaseInput) {
        const customerYear = bikeCase.customerYear;
        const bikeIdYear = this.bikeYearService.fromBikeId(bikeCase);
        const invoiceYear = this.bikeYearService.fromDate(bikeCase.automatedInvoicePurchaseDate);
        return this.bestEstimateService.getExpectedYear(customerYear, bikeIdYear, invoiceYear);
    }
}
