import { BabboeBikeModel } from '../dtos/salesforce-bike-recall-case';
import { BabboeCargoBikeIdentificationResult } from '../enums/babboe-cargo-bike-identification-result';
import { BabboeCargoBikeTypes } from '../enums/babboe-cargo-bike-types';

export class ModelIdentificationResultService {
    private babboeBikeModelToIdentificationResult: Record<BabboeBikeModel, BabboeCargoBikeIdentificationResult> = {
        [BabboeBikeModel.BabboeBig]: BabboeCargoBikeIdentificationResult.Big,
        [BabboeBikeModel.BabboeBigE]: BabboeCargoBikeIdentificationResult.BigE,
        [BabboeBikeModel.BabboeCurve]: BabboeCargoBikeIdentificationResult.Curve,
        [BabboeBikeModel.BabboeCurveE]: BabboeCargoBikeIdentificationResult.CurveE,
        [BabboeBikeModel.BabboeCurveMountain]: BabboeCargoBikeIdentificationResult.CurveMountain,
        [BabboeBikeModel.BabboeFlowE]: BabboeCargoBikeIdentificationResult.FlowE,
        [BabboeBikeModel.BabboeFlowMountain]: BabboeCargoBikeIdentificationResult.FlowMountain,
        [BabboeBikeModel.BabboeMini]: BabboeCargoBikeIdentificationResult.Mini,
        [BabboeBikeModel.BabboeMiniE]: BabboeCargoBikeIdentificationResult.MiniE,
        [BabboeBikeModel.BabboeMiniMountain]: BabboeCargoBikeIdentificationResult.MiniMountain,
        [BabboeBikeModel.BabboeSlimMountain]: BabboeCargoBikeIdentificationResult.SlimMountain,
        [BabboeBikeModel.BabboeCity]: BabboeCargoBikeIdentificationResult.City,
        [BabboeBikeModel.BabboeCityE]: BabboeCargoBikeIdentificationResult.CityE,
        [BabboeBikeModel.BabboeCityMountain]: BabboeCargoBikeIdentificationResult.CityMountain,
        [BabboeBikeModel.BabboeGo]: BabboeCargoBikeIdentificationResult.Go,
        [BabboeBikeModel.BabboeGoE]: BabboeCargoBikeIdentificationResult.GoE,
        [BabboeBikeModel.BabboeGoMountain]: BabboeCargoBikeIdentificationResult.GoMountain,
        [BabboeBikeModel.BabboeCarveE]: BabboeCargoBikeIdentificationResult.CarveE,
        [BabboeBikeModel.BabboeCarveMountain]: BabboeCargoBikeIdentificationResult.CarveMountain,
        [BabboeBikeModel.BabboeMaxE]: BabboeCargoBikeIdentificationResult.MaxE,
        [BabboeBikeModel.BabboeDog]: BabboeCargoBikeIdentificationResult.Dog,
        [BabboeBikeModel.BabboeDogE]: BabboeCargoBikeIdentificationResult.DogE,
        [BabboeBikeModel.BabboeProBike]: BabboeCargoBikeIdentificationResult.ProBike,
        [BabboeBikeModel.BabboeProBikeE]: BabboeCargoBikeIdentificationResult.ProBikeE,
        [BabboeBikeModel.BabboeProBikeMidmotor]: BabboeCargoBikeIdentificationResult.ProBikeMidMotor,
        [BabboeBikeModel.BabboeProTrike]: BabboeCargoBikeIdentificationResult.ProTrike,
        [BabboeBikeModel.BabboeProTrikeE]: BabboeCargoBikeIdentificationResult.ProTrikeE,
        [BabboeBikeModel.BabboeProTrikeXL]: BabboeCargoBikeIdentificationResult.ProTrikeXlMidMotor,
        [BabboeBikeModel.BabboeTransporter]: BabboeCargoBikeIdentificationResult.Transporter,
        [BabboeBikeModel.BabboeTransporterE]: BabboeCargoBikeIdentificationResult.TransporterE,
        [BabboeBikeModel.Unknown]: BabboeCargoBikeIdentificationResult.Unknown,
    };

    private babboeCargoBikeTypesToIdentificationResult: Record<
        BabboeCargoBikeTypes | 'Unknown',
        BabboeCargoBikeIdentificationResult
    > = {
        [BabboeCargoBikeTypes.Big]: BabboeCargoBikeIdentificationResult.Big,
        [BabboeCargoBikeTypes.BigE]: BabboeCargoBikeIdentificationResult.BigE,
        [BabboeCargoBikeTypes.Curve]: BabboeCargoBikeIdentificationResult.Curve,
        [BabboeCargoBikeTypes.CurveE]: BabboeCargoBikeIdentificationResult.CurveE,
        [BabboeCargoBikeTypes.CurveMountain]: BabboeCargoBikeIdentificationResult.CurveMountain,
        [BabboeCargoBikeTypes.FlowE]: BabboeCargoBikeIdentificationResult.FlowE,
        [BabboeCargoBikeTypes.FlowMountain]: BabboeCargoBikeIdentificationResult.FlowMountain,
        [BabboeCargoBikeTypes.Mini]: BabboeCargoBikeIdentificationResult.Mini,
        [BabboeCargoBikeTypes.MiniE]: BabboeCargoBikeIdentificationResult.MiniE,
        [BabboeCargoBikeTypes.MiniMountain]: BabboeCargoBikeIdentificationResult.MiniMountain,
        [BabboeCargoBikeTypes.SlimMountain]: BabboeCargoBikeIdentificationResult.SlimMountain,
        [BabboeCargoBikeTypes.City]: BabboeCargoBikeIdentificationResult.City,
        [BabboeCargoBikeTypes.CityE]: BabboeCargoBikeIdentificationResult.CityE,
        [BabboeCargoBikeTypes.CityMountain]: BabboeCargoBikeIdentificationResult.CityMountain,
        [BabboeCargoBikeTypes.Go]: BabboeCargoBikeIdentificationResult.Go,
        [BabboeCargoBikeTypes.GoE]: BabboeCargoBikeIdentificationResult.GoE,
        [BabboeCargoBikeTypes.GoMountain]: BabboeCargoBikeIdentificationResult.GoMountain,
        [BabboeCargoBikeTypes.CarveE]: BabboeCargoBikeIdentificationResult.CarveE,
        [BabboeCargoBikeTypes.CarveMountain]: BabboeCargoBikeIdentificationResult.CarveMountain,
        [BabboeCargoBikeTypes.MaxE]: BabboeCargoBikeIdentificationResult.MaxE,
        [BabboeCargoBikeTypes.Dog]: BabboeCargoBikeIdentificationResult.Dog,
        [BabboeCargoBikeTypes.DogE]: BabboeCargoBikeIdentificationResult.DogE,
        [BabboeCargoBikeTypes.ProBike]: BabboeCargoBikeIdentificationResult.ProBike,
        [BabboeCargoBikeTypes.ProBikeE]: BabboeCargoBikeIdentificationResult.ProBikeE,
        [BabboeCargoBikeTypes.ProBikeMidMotor]: BabboeCargoBikeIdentificationResult.ProBikeMidMotor,
        [BabboeCargoBikeTypes.ProTrike]: BabboeCargoBikeIdentificationResult.ProTrike,
        [BabboeCargoBikeTypes.ProTrikeE]: BabboeCargoBikeIdentificationResult.ProTrikeE,
        [BabboeCargoBikeTypes.ProTrikeXlMidMotor]: BabboeCargoBikeIdentificationResult.ProTrikeXlMidMotor,
        [BabboeCargoBikeTypes.Transporter]: BabboeCargoBikeIdentificationResult.Transporter,
        [BabboeCargoBikeTypes.TransporterE]: BabboeCargoBikeIdentificationResult.TransporterE,
        ['Unknown']: BabboeCargoBikeIdentificationResult.Unknown,
    };

    fromCustomerModel(customerModel: BabboeBikeModel) {
        return this.babboeBikeModelToIdentificationResult[customerModel];
    }

    fromBikeType(bikeType: BabboeCargoBikeTypes | undefined) {
        return bikeType ? this.babboeCargoBikeTypesToIdentificationResult[bikeType] : BabboeCargoBikeIdentificationResult.Unknown;
    }
}
