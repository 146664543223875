import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from '../button/button.component';
import { TextInputComponent } from '../text-input/text-input.component';
import { RouterLink } from '@angular/router';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'algemi-batch-selection',
    standalone: true,
    imports: [CommonModule, ButtonComponent, TextInputComponent, FormsModule, RouterLink],
    templateUrl: './batch-selection.component.html',
    styleUrl: './batch-selection.component.scss',
})
export class BatchSelectionComponent {
    batchName = '';
}
