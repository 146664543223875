import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BikeIdentificationCase } from '../../../interfaces/bike-identification-case';
import { Router, RouterModule } from '@angular/router';
import {
    BabboeBikeModel,
    BabboeCargoBikeIdentificationResult,
    BabboeCargoBikeTypes,
    BestEstimateService,
    BikeRecallCaseDto,
    BikeYearService,
    Country,
    ModelIdentificationResultService,
    RestOfWorldService,
} from '@algemi/accell/shared/babboe-bike-detection';
import { ChangeDayComponent } from '../../change-day/change-day.component';

type CaseType = Partial<BikeIdentificationCase> & { id: string };

@Component({
    selector: 'algemi-additional-data-flow-case-list',
    standalone: true,
    imports: [CommonModule, RouterModule, ChangeDayComponent],
    providers: [BestEstimateService, ModelIdentificationResultService, BikeYearService, RestOfWorldService],
    templateUrl: './additional-data-flow-case-list.component.html',
    styleUrl: './additional-data-flow-case-list.component.scss',
})
export class AdditionalDataFlowCaseListComponent {
    batchName = input.required<string | undefined>();
    cases = input.required<BikeRecallCaseDto[]>();
    selectedCaseId = input<string>();
    navigateSlice = input<number>(4);
    showDaySwitcher = input<boolean>(false);

    // private validModelsForBikePhotoIdentification: Record<BabboeCargoBikeModels, BabboeCargoBikeTypes[]> = {
    //     [BabboeCargoBikeModels.Big]: [BabboeCargoBikeTypes.BabboeBig, BabboeCargoBikeTypes.BabboeBigE],
    //     [BabboeCargoBikeModels.Curve]: [
    //         BabboeCargoBikeTypes.BabboeCurve,
    //         BabboeCargoBikeTypes.BabboeCurveE,
    //         BabboeCargoBikeTypes.BabboeCurveMountain,
    //     ],
    //     [BabboeCargoBikeModels.Flow]: [BabboeCargoBikeTypes.BabboeFlowE, BabboeCargoBikeTypes.BabboeFlowMountain],
    //     [BabboeCargoBikeModels.Mini]: [
    //         BabboeCargoBikeTypes.BabboeMini,
    //         BabboeCargoBikeTypes.BabboeMiniMountain,
    //         BabboeCargoBikeTypes.BabboeMiniE,
    //     ],
    //     [BabboeCargoBikeModels.Slim]: [BabboeCargoBikeTypes.BabboeSlimMountain],
    //     [BabboeCargoBikeModels.City]: [
    //         BabboeCargoBikeTypes.BabboeCity,
    //         BabboeCargoBikeTypes.BabboeCityE,
    //         BabboeCargoBikeTypes.BabboeCityMountain,
    //     ],
    //     [BabboeCargoBikeModels.Go]: [BabboeCargoBikeTypes.BabboeGo, BabboeCargoBikeTypes.BabboeGoE, BabboeCargoBikeTypes.BabboeGoMountain],
    //     [BabboeCargoBikeModels.Carve]: [BabboeCargoBikeTypes.BabboeCarveE, BabboeCargoBikeTypes.BabboeCarveMountain],
    //     [BabboeCargoBikeModels.Max]: [BabboeCargoBikeTypes.BabboeMaxE],
    //     [BabboeCargoBikeModels.Dog]: [BabboeCargoBikeTypes.BabboeDog, BabboeCargoBikeTypes.BabboeDogE],
    //     [BabboeCargoBikeModels.ProBike]: [
    //         BabboeCargoBikeTypes.BabboeProBike,
    //         BabboeCargoBikeTypes.BabboeProBikeMidmotor,
    //         BabboeCargoBikeTypes.BabboeProBikeE,
    //     ],
    //     [BabboeCargoBikeModels.ProTrike]: [BabboeCargoBikeTypes.BabboeProTrike, BabboeCargoBikeTypes.BabboeProTrikeE],
    //     [BabboeCargoBikeModels.ProTrikeXl]: [BabboeCargoBikeTypes.BabboeProTrikeXL],
    //     [BabboeCargoBikeModels.Transporter]: [BabboeCargoBikeTypes.BabboeTransporterE, BabboeCargoBikeTypes.BabboeTransporter],
    // };
    //
    // private validModelsForMountainIdentification: Record<'true' | 'false', BabboeBikeModel[]> = {
    //     true: [
    //         BabboeCargoBikeTypes.BabboeCurveMountain,
    //         BabboeCargoBikeTypes.BabboeFlowMountain,
    //         BabboeCargoBikeTypes.BabboeGoMountain,
    //         BabboeCargoBikeTypes.BabboeCarveMountain,
    //         BabboeCargoBikeTypes.BabboeCityMountain,
    //         BabboeCargoBikeTypes.BabboeSlimMountain,
    //         BabboeCargoBikeTypes.BabboeMiniMountain,
    //     ],
    //     false: [
    //         BabboeCargoBikeTypes.BabboeBigE,
    //         BabboeCargoBikeTypes.BabboeDogE,
    //         BabboeCargoBikeTypes.BabboeCityE,
    //         BabboeCargoBikeTypes.BabboeGoE,
    //         BabboeCargoBikeTypes.BabboeFlowE,
    //         BabboeCargoBikeTypes.BabboeCurveE,
    //         BabboeCargoBikeTypes.BabboeBig,
    //         BabboeCargoBikeTypes.BabboeProTrikeE,
    //         BabboeCargoBikeTypes.BabboeCurve,
    //         BabboeCargoBikeTypes.BabboeMaxE,
    //         BabboeCargoBikeTypes.BabboeMiniE,
    //         BabboeCargoBikeTypes.BabboeDog,
    //         BabboeCargoBikeTypes.BabboeProBike,
    //         BabboeCargoBikeTypes.BabboeProTrikeXL,
    //         BabboeCargoBikeTypes.BabboeProBikeMidmotor,
    //         BabboeCargoBikeTypes.BabboeCarveE,
    //         BabboeCargoBikeTypes.BabboeProTrike,
    //         BabboeCargoBikeTypes.BabboeCity,
    //         BabboeCargoBikeTypes.BabboeProBikeE,
    //         BabboeCargoBikeTypes.BabboeGo,
    //         BabboeCargoBikeTypes.BabboeMini,
    //         BabboeCargoBikeTypes.BabboeTransporterE,
    //         BabboeCargoBikeTypes.BabboeTransporter,
    //     ],
    // };

    private validModelsForInvoiceIdentification: Record<BabboeCargoBikeIdentificationResult, BabboeBikeModel[]> = {
        [BabboeCargoBikeIdentificationResult.Big]: [BabboeBikeModel.BabboeBig],
        [BabboeCargoBikeIdentificationResult.BigE]: [BabboeBikeModel.BabboeBigE],
        [BabboeCargoBikeIdentificationResult.Curve]: [BabboeBikeModel.BabboeCurve],
        [BabboeCargoBikeIdentificationResult.CurveE]: [BabboeBikeModel.BabboeCurveE],
        [BabboeCargoBikeIdentificationResult.CurveMountain]: [BabboeBikeModel.BabboeCurveMountain],
        [BabboeCargoBikeIdentificationResult.FlowE]: [BabboeBikeModel.BabboeFlowE],
        [BabboeCargoBikeIdentificationResult.FlowMountain]: [BabboeBikeModel.BabboeFlowMountain],
        [BabboeCargoBikeIdentificationResult.Mini]: [BabboeBikeModel.BabboeMini],
        [BabboeCargoBikeIdentificationResult.MiniE]: [BabboeBikeModel.BabboeMiniE],
        [BabboeCargoBikeIdentificationResult.MiniMountain]: [BabboeBikeModel.BabboeMiniMountain],
        [BabboeCargoBikeIdentificationResult.SlimMountain]: [BabboeBikeModel.BabboeSlimMountain],
        [BabboeCargoBikeIdentificationResult.City]: [BabboeBikeModel.BabboeCity],
        [BabboeCargoBikeIdentificationResult.CityE]: [BabboeBikeModel.BabboeCityE],
        [BabboeCargoBikeIdentificationResult.CityMountain]: [BabboeBikeModel.BabboeCityMountain],
        [BabboeCargoBikeIdentificationResult.Go]: [BabboeBikeModel.BabboeGo],
        [BabboeCargoBikeIdentificationResult.GoE]: [BabboeBikeModel.BabboeGoE],
        [BabboeCargoBikeIdentificationResult.GoMountain]: [BabboeBikeModel.BabboeGoMountain],
        [BabboeCargoBikeIdentificationResult.CarveE]: [BabboeBikeModel.BabboeCarveE],
        [BabboeCargoBikeIdentificationResult.CarveMountain]: [BabboeBikeModel.BabboeCarveMountain],
        [BabboeCargoBikeIdentificationResult.MaxE]: [BabboeBikeModel.BabboeMaxE],
        [BabboeCargoBikeIdentificationResult.Dog]: [BabboeBikeModel.BabboeDog],
        [BabboeCargoBikeIdentificationResult.DogE]: [BabboeBikeModel.BabboeDogE],
        [BabboeCargoBikeIdentificationResult.ProBike]: [BabboeBikeModel.BabboeProBike],
        [BabboeCargoBikeIdentificationResult.ProBikeE]: [BabboeBikeModel.BabboeProBikeE],
        [BabboeCargoBikeIdentificationResult.ProBikeMidMotor]: [BabboeBikeModel.BabboeProBikeMidmotor],
        [BabboeCargoBikeIdentificationResult.ProTrike]: [BabboeBikeModel.BabboeProTrike],
        [BabboeCargoBikeIdentificationResult.ProTrikeE]: [BabboeBikeModel.BabboeProTrikeE],
        [BabboeCargoBikeIdentificationResult.ProTrikeXlMidMotor]: [BabboeBikeModel.BabboeProTrikeXL],
        [BabboeCargoBikeIdentificationResult.Transporter]: [BabboeBikeModel.BabboeTransporter],
        [BabboeCargoBikeIdentificationResult.TransporterE]: [BabboeBikeModel.BabboeTransporterE],
        [BabboeCargoBikeIdentificationResult.Unknown]: [BabboeBikeModel.Unknown],
    };

    constructor(
        private readonly router: Router,
        private readonly bestEstimateService: BestEstimateService,
        private readonly modelIdentificationResultService: ModelIdentificationResultService,
        private readonly bikeYearService: BikeYearService,
        private readonly restOfWorldService: RestOfWorldService,
    ) {}

    navigate(ticketId: string) {
        const newUrl = [...this.router.url.split('/').slice(0, this.navigateSlice()), ticketId].join('/');
        this.router.navigateByUrl(newUrl);
    }

    // doesBikeIdentificationMatchIdentifiedModel(bikeCase: BikeRecallCaseDto): boolean {
    //     if (bikeCase.automatedIdentifiedBikeModel === undefined || bikeCase.photoIdentifiedBikeModel === undefined) {
    //         return false;
    //     }
    //     const validModels = this.validModelsForBikePhotoIdentification[bikeCase.automatedIdentifiedBikeModel];
    //     return validModels.includes(bikeCase.photoIdentifiedBikeModel);
    // }
    //
    // doesBikePedalIdentificationMatchIdentifiedModel(bikeCase: BikeRecallCaseDto): boolean {
    //     const validModels =
    //         bikeCase.automatedIsMountainModel !== undefined
    //             ? this.validModelsForMountainIdentification[`${bikeCase.automatedIsMountainModel}`]
    //             : [];
    //     return validModels.includes(bikeCase.model);
    // }

    doesBikeTypeMatchCustomerModel(
        bikeType: BabboeCargoBikeIdentificationResult | undefined,
        bikeModel: BabboeBikeModel,
        returnValueWhenUndefined: boolean,
    ): boolean {
        if (bikeType === undefined) {
            return returnValueWhenUndefined;
        }
        const validModels = this.validModelsForInvoiceIdentification[bikeType] ?? [];
        return validModels.includes(bikeModel);
    }

    wasAnalysisPerformed(bikeCase: BikeRecallCaseDto) {
        return (
            bikeCase.automatedIdentifiedBikeModel !== undefined ||
            bikeCase.automatedIsMountainModel !== undefined ||
            bikeCase.automatedInvoiceBikeType !== undefined ||
            bikeCase.automatedInvoicePurchaseDate !== undefined
        );
    }

    whereAllQualityChecksComplete(bikeCase: BikeRecallCaseDto) {
        return bikeCase.emailAttachmentIdentifiedBikeModel !== undefined && this.isAdditionalDataFlowApproved(bikeCase);
    }
    isAdditionalDataFlowApproved(bikeCase: BikeRecallCaseDto) {
        const batchName = this.batchName();
        if (!batchName) {
            return false;
        }
        return bikeCase.isAdditionalDataFlowApproved ? bikeCase.isAdditionalDataFlowApproved[batchName] : false;
    }

    private doesDateStringMatchCustomerDateString(
        dateString: string | undefined,
        customerDate: number | string,
        returnValueWhenUndefined: boolean,
    ) {
        if (dateString === undefined) {
            return returnValueWhenUndefined;
        }
        const year = dateString.split('-')[2];
        return (
            year === `${customerDate}` ||
            (customerDate === '2024/2023' && (year === '2024' || year === '2023')) ||
            customerDate === 'Unknown' ||
            (typeof customerDate === 'number' && customerDate + 1 === parseInt(year))
        );
    }

    doAllAvailableBikeIdentificationChecksMatch(bikeCase: BikeRecallCaseDto) {
        return (
            this.doesBikeTypeMatchCustomerModel(bikeCase.photoIdentifiedBikeModel, bikeCase.model, true) &&
            this.doesBikeTypeMatchCustomerModel(bikeCase.automatedInvoiceBikeType, bikeCase.model, true)
        );
    }

    doAllAvailableDateIdentificationChecksMatch(bikeCase: BikeRecallCaseDto) {
        return this.doesDateStringMatchCustomerDateString(bikeCase.automatedInvoicePurchaseDate, bikeCase.modelYear, true);
    }

    isFlagged(bikeCase: BikeRecallCaseDto) {
        return bikeCase.isFlaggedForReviewByCustomerService; //|| bikeCase.isFlaggedAsOverrulingCustomer;
    }
    getBikeBestEstimate(bikeCase: BikeRecallCaseDto) {
        const customerModel = this.modelIdentificationResultService.fromCustomerModel(bikeCase.model);
        const photoBikeModel = this.modelIdentificationResultService.fromBikeType(
            bikeCase.photoIdentifiedBikeModel as never as BabboeCargoBikeTypes,
        );
        const invoiceModel = this.modelIdentificationResultService.fromBikeType(
            bikeCase.automatedInvoiceBikeType as never as BabboeCargoBikeTypes,
        );
        return this.bestEstimateService.getExpectedBikeType(customerModel, photoBikeModel, invoiceModel);
    }

    hasBikeModelDiscrepancy(bikeCase: BikeRecallCaseDto) {
        const customerModel = this.modelIdentificationResultService.fromCustomerModel(bikeCase.model);
        const photoBikeModel = this.modelIdentificationResultService.fromBikeType(
            bikeCase.photoIdentifiedBikeModel as never as BabboeCargoBikeTypes,
        );
        const invoiceModel = this.modelIdentificationResultService.fromBikeType(
            bikeCase.automatedInvoiceBikeType as never as BabboeCargoBikeTypes,
        );
        return this.bestEstimateService.flagModelDiscrepancy(customerModel, photoBikeModel, invoiceModel);
    }
    hasYearDiscrepancy(bikeCase: BikeRecallCaseDto) {
        const customerYear = bikeCase.modelYear;
        const bikeIdYear = this.bikeYearService.fromBikeId(bikeCase);
        const invoiceYear = this.bikeYearService.fromDate(bikeCase.automatedInvoicePurchaseDate);
        return this.bestEstimateService.flagYearDiscrepancy(customerYear, bikeIdYear, invoiceYear);
    }

    getYearBestEstimate(bikeCase: BikeRecallCaseDto) {
        const customerYear = bikeCase.modelYear;
        const bikeIdYear = this.bikeYearService.fromBikeId(bikeCase);
        const invoiceYear = this.bikeYearService.fromDate(bikeCase.automatedInvoicePurchaseDate);
        return this.bestEstimateService.getExpectedYear(customerYear, bikeIdYear, invoiceYear);
    }
    getIsBikeModelSameAsCustomer(bikeCase: BikeRecallCaseDto) {
        const customerModel = this.modelIdentificationResultService.fromCustomerModel(bikeCase.model);
        const bestEstimateModel = bikeCase.emailAttachmentIdentifiedBikeModel ?? BabboeCargoBikeIdentificationResult.Unknown;
        return bestEstimateModel === BabboeCargoBikeIdentificationResult.Unknown || customerModel === bestEstimateModel;
    }

    protected doPhotoAndInvoiceMismatch(bikeRecallCaseDto: BikeRecallCaseDto) {
        if (
            bikeRecallCaseDto.photoIdentifiedBikeModel === BabboeCargoBikeIdentificationResult.Unknown ||
            bikeRecallCaseDto.automatedInvoiceBikeType === BabboeCargoBikeIdentificationResult.Unknown ||
            bikeRecallCaseDto.automatedInvoiceBikeType === undefined
        ) {
            return false;
        }
        if (bikeRecallCaseDto.photoIdentifiedBikeModel === bikeRecallCaseDto.automatedInvoiceBikeType) {
            return false;
        }
        return true;
    }

    protected hasSingleEstimateThatMismatchesCustomer(bikeRecallCaseDto: BikeRecallCaseDto) {
        const customerModel = this.modelIdentificationResultService.fromCustomerModel(bikeRecallCaseDto.model);
        if (
            bikeRecallCaseDto.photoIdentifiedBikeModel === BabboeCargoBikeIdentificationResult.Unknown &&
            bikeRecallCaseDto.automatedInvoiceBikeType !== BabboeCargoBikeIdentificationResult.Unknown &&
            bikeRecallCaseDto.automatedInvoiceBikeType !== undefined
        ) {
            if (bikeRecallCaseDto.automatedInvoiceBikeType !== customerModel) {
                return true;
            }
        }
        if (
            bikeRecallCaseDto.photoIdentifiedBikeModel !== BabboeCargoBikeIdentificationResult.Unknown &&
            (bikeRecallCaseDto.automatedInvoiceBikeType === BabboeCargoBikeIdentificationResult.Unknown ||
                bikeRecallCaseDto.automatedInvoiceBikeType === undefined)
        ) {
            if (bikeRecallCaseDto.photoIdentifiedBikeModel !== customerModel) {
                return true;
            }
        }
        return false;
    }

    protected readonly BabboeCargoBikeIdentificationResult = BabboeCargoBikeIdentificationResult;

    isCountryForWhichInvoiceNeedsToBeAnalyzed(bikeCase: BikeRecallCaseDto) {
        return this.restOfWorldService.isRestOfWorld(bikeCase.country);
    }
}
