import { Route } from '@angular/router';
import { MenuComponent } from './components/menu/menu.component';
import { BatchSelectionComponent } from './components/batch-selection/batch-selection.component';
import { CaseBrowserPageComponent } from './components/case-browser-page/case-browser-page.component';
import { CasePhotoCheckerPageComponent } from './components/case-photo-checker/case-photo-checker-page.component';
import { InvoiceBrowserPageComponent } from './components/invoice-browser-page/invoice-browser-page.component';
import { BikePhotoCheckPageComponent } from './components/bike-photo-check-page/bike-photo-check-page.component';
import { InvoiceBikeIdentificationPageComponent } from './components/invoice-bike-identification-browser-page/invoice-bike-identification-page.component';
import { FlaggedCaseBrowserPageComponent } from './components/flagged-case-browser-page/flagged-case-browser-page.component';
import { AdditionalDataFlowInvoiceBrowserPageComponent } from './components/additional-data-flow/additional-data-flow-invoice-browser-page/additional-data-flow-invoice-browser-page.component';
import { AdditionalDataFlowFlaggedCaseBrowserPageComponent } from './components/additional-data-flow/additional-data-flow-flagged-case-browser-page/additional-data-flow-flagged-case-browser-page.component';
import { AdditionalDataFlowMenuComponent } from './components/additional-data-flow/additional-data-flow-menu/additional-data-flow-menu.component';
import { AdditionalDataFlowCaseBrowserPageComponent } from './components/additional-data-flow/additional-data-flow-case-browser-page/additional-data-flow-case-browser-page.component';

export const appRoutes: Route[] = [
    {
        path: '',
        component: BatchSelectionComponent,
    },
    {
        path: 'additional-data-flow',
        children: [
            {
                path: ':batchName',
                children: [
                    {
                        path: '',
                        component: AdditionalDataFlowMenuComponent,
                    },
                    {
                        path: 'case-browser',
                        children: [
                            {
                                path: '',
                                component: AdditionalDataFlowCaseBrowserPageComponent,
                            },
                            {
                                path: ':ticketId',
                                component: AdditionalDataFlowCaseBrowserPageComponent,
                            },
                        ],
                    },
                    {
                        path: 'invoice-browser',
                        children: [
                            {
                                path: '',
                                component: AdditionalDataFlowInvoiceBrowserPageComponent,
                            },
                            {
                                path: ':ticketId',
                                component: AdditionalDataFlowInvoiceBrowserPageComponent,
                            },
                        ],
                    },
                    {
                        path: 'flagged-case-browser',
                        children: [
                            {
                                path: '',
                                component: AdditionalDataFlowFlaggedCaseBrowserPageComponent,
                            },
                            {
                                path: ':ticketId',
                                component: AdditionalDataFlowFlaggedCaseBrowserPageComponent,
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        path: ':batchName',
        children: [
            {
                path: '',
                component: MenuComponent,
            },
            {
                path: 'case-browser',
                children: [
                    {
                        path: '',
                        component: CaseBrowserPageComponent,
                    },
                    {
                        path: ':ticketId',
                        component: CaseBrowserPageComponent,
                    },
                ],
            },
            {
                path: 'case-photo-checker',
                children: [
                    {
                        path: '',
                        component: CasePhotoCheckerPageComponent,
                    },
                    {
                        path: ':ticketId',
                        component: CasePhotoCheckerPageComponent,
                    },
                ],
            },
            {
                path: 'invoice-browser',
                children: [
                    {
                        path: '',
                        component: InvoiceBrowserPageComponent,
                    },
                    {
                        path: ':ticketId',
                        component: InvoiceBrowserPageComponent,
                    },
                ],
            },
            {
                path: 'bike-photo-check',
                children: [
                    {
                        path: '',
                        component: BikePhotoCheckPageComponent,
                    },
                    {
                        path: ':ticketId',
                        component: BikePhotoCheckPageComponent,
                    },
                ],
            },
            {
                path: 'invoice-bike-identification',
                children: [
                    {
                        path: '',
                        component: InvoiceBikeIdentificationPageComponent,
                    },
                    {
                        path: ':ticketId',
                        component: InvoiceBikeIdentificationPageComponent,
                    },
                ],
            },

            {
                path: 'flagged-case-browser',
                children: [
                    {
                        path: '',
                        component: FlaggedCaseBrowserPageComponent,
                    },
                    {
                        path: ':ticketId',
                        component: FlaggedCaseBrowserPageComponent,
                    },
                ],
            },
        ],
    },
];
