import { Component, Input, model } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'algemi-text-input',
    standalone: true,
    imports: [CommonModule, FormsModule],
    templateUrl: './text-input.component.html',
    styleUrl: './text-input.component.scss',
})
export class TextInputComponent {
    private static index = 0;

    protected id = `algemi-text-input-${++TextInputComponent.index}`;

    @Input() label: string | undefined;
    @Input() placeholder = '';

    value = model('');
}
