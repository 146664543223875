@if (showDaySwitcher()){
<algemi-change-day></algemi-change-day>
} @for(bikeCase of cases();track bikeCase.id; let index = $index){
<div
    class="ticket-container"
    (click)="navigate(bikeCase.id)"
    [ngClass]="{
        selected: bikeCase.id === selectedCaseId(),
        analyzed: wasAnalysisPerformed(bikeCase),
        approved: bikeCase.isBikePhotoManuallyChecked
    }"
>
    <b>{{ index + 1 }}</b
    >: {{ bikeCase.id }}
    <div class="flag-container">
        <div *ngIf="bikeCase.isBikePhotoManuallyChecked" class="mask check"></div>
        <div
            [ngClass]="{
                unavailable: !bikeCase.bikePhotoDataUrl,
                hasIdentifiedBike: bikeCase.photoIdentifiedBikeModel
            }"
        >
            BP
        </div>
        <div
            [ngClass]="{
                unavailable: !bikeCase.bikePedalPhotoDataUrl,
                hasIdentifiedBike: bikeCase.photoIdentifiedBikeModel
            }"
        >
            BPP
        </div>
        <div
            [ngClass]="{
                unavailable: !bikeCase.invoiceDataUrl,
                hasIdentifiedBike: bikeCase.automatedInvoiceBikeType
            }"
        >
            I
        </div>
        <div
            class="mask bike"
            [ngClass]="{
                unavailable: getBikeBestEstimate(bikeCase) !== 'Unknown'
            }"
        ></div>
        <div
            class="mask customer"
            [ngClass]="{
                unavailable: getIsBikeModelSameAsCustomer(bikeCase)
            }"
        ></div>
        <div
            class="mask flag"
            [ngClass]="{
                unavailable: !isFlagged(bikeCase)
            }"
        ></div>
    </div>
</div>
}
