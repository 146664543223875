import {
    Component,
    computed,
    effect,
    ElementRef,
    HostListener,
    OnDestroy,
    QueryList,
    signal,
    Signal,
    ViewChildren,
    WritableSignal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { toSignal } from '@angular/core/rxjs-interop';
import { filter, interval, map, Subscription } from 'rxjs';
import { BackendService } from '../../services/backend.service';
import { ButtonComponent } from '../button/button.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import {
    BabboeCargoBikeIdentificationResult,
    BabboeCargoBikeTypes,
    BestEstimateService,
    BikeRecallCaseDto,
    BikeYearService,
    ModelIdentificationResultService,
} from '@algemi/accell/shared/babboe-bike-detection';
import { OcrImageComponent } from '../ocr-image-component/ocr-image.component';
import { CaseListComponent } from '../case-list/case-list.component';
import { BikeTypeSelectionComponent } from '../bike-type-selection/bike-type-selection.component';
import { PurchaseDateInputComponent } from '../purchase-date-input/purchase-date-input.component';
import { ToggleComponent } from '../toggle/toggle.component';
import { TextInputComponent } from '../text-input/text-input.component';

enum ReasonToShow {
    InvalidDate = 1,
    PhotoIdentificationAndInvoiceMismatch = 2,
    SingleEstimateMismatchCustomer = 3,
    UnidentifiedBike = 4,
    NothingWrong = 9,
}

@Component({
    selector: 'algemi-case-browser-page',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        ButtonComponent,
        PdfViewerModule,
        OcrImageComponent,
        CaseListComponent,
        BikeTypeSelectionComponent,
        PurchaseDateInputComponent,
        ToggleComponent,
        TextInputComponent,
    ],
    providers: [BestEstimateService, ModelIdentificationResultService, BikeYearService],
    templateUrl: './case-browser-page.component.html',
    styleUrl: './case-browser-page.component.scss',
})
export class CaseBrowserPageComponent implements OnDestroy {
    protected batchName: Signal<string | undefined>;
    protected caseId: Signal<string | undefined>;
    protected cases: WritableSignal<BikeRecallCaseDto[]>;
    protected selectedCaseIndex: Signal<number | undefined>;
    protected selectedCase: WritableSignal<BikeRecallCaseDto | undefined>;
    protected invoiceFileType: Signal<'pdf' | 'img' | undefined> = signal(undefined);
    @ViewChildren('anchor') anchors!: QueryList<ElementRef<HTMLElement>>;
    viewChildIndex = 0;
    private heartBeat$ = interval(2000);
    protected shouldAutomaticallyForward = false;
    private subscriptions: Subscription[] = [];
    protected reasonForFlaggingForCustomerService = '';

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        protected readonly backendService: BackendService,
        private readonly router: Router,
        private readonly bestEstimateService: BestEstimateService,
        private readonly modelIdentificationResultService: ModelIdentificationResultService,
        private readonly bikeYearService: BikeYearService,
    ) {
        this.batchName = toSignal(this.activatedRoute.params.pipe(map((p) => p['batchName'])));
        this.caseId = toSignal(this.activatedRoute.params.pipe(map((p) => p['ticketId'])));

        this.selectedCaseIndex = computed(() => this.cases().findIndex((t) => t.id === this.caseId()));

        this.cases = signal<BikeRecallCaseDto[]>([]);
        effect(
            async () => {
                const batchName = this.batchName();
                const allCases = batchName ? await this.backendService.getBikeCases(batchName) : [];
                return this.cases.set(
                    allCases
                        .map((c) => ({
                            ...c,
                            reasonForShowing: `${
                                this.hasYearDiscrepancy(c)
                                    ? ReasonToShow.InvalidDate
                                    : this.doPhotoAndInvoiceMismatch(c)
                                    ? ReasonToShow.PhotoIdentificationAndInvoiceMismatch
                                    : this.hasSingleEstimateThatMismatchesCustomer(c)
                                    ? ReasonToShow.SingleEstimateMismatchCustomer
                                    : this.getBikeBestEstimate(c) === BabboeCargoBikeIdentificationResult.Unknown
                                    ? ReasonToShow.UnidentifiedBike
                                    : ReasonToShow.NothingWrong
                            }-${this.getBikeBestEstimate(c)}`,
                        }))
                        .sort((a, b) => (a.reasonForShowing < b.reasonForShowing ? -1 : 1)),
                );
            },
            { allowSignalWrites: true },
        );
        this.selectedCase = signal<BikeRecallCaseDto | undefined>(undefined);
        effect(
            () => {
                const batchName = this.batchName();
                const caseId = this.caseId();
                if (batchName && caseId && (this.selectedCaseIndex() ?? -1) >= 0) {
                    this.backendService.getCase(batchName, caseId).then((c) => this.selectedCase.set(c));
                }
                this.selectedCase.set(undefined);
                this.viewChildIndex = 0;
            },
            { allowSignalWrites: true },
        );
        this.invoiceFileType = computed(() => {
            const bikeCase = this.selectedCase();
            return bikeCase?.invoiceType as never;
        });
        this.subscriptions.push(
            this.heartBeat$.pipe(filter(() => this.shouldAutomaticallyForward)).subscribe(() => {
                this.autoForward();
            }),
        );
    }

    private goToPreviousTicket() {
        this.viewChildIndex = 0;
        let previousTicketIndex = (this.selectedCaseIndex() ?? -1) - 1;
        if (previousTicketIndex < 0) {
            previousTicketIndex = this.cases().length - 1;
        }
        this.router.navigateByUrl(`/${this.batchName()}/case-browser/${this.cases()[previousTicketIndex].id}`);
    }

    private goToNextTicket() {
        this.viewChildIndex = 0;
        let nextTicketIndex = (this.selectedCaseIndex() ?? -1) + 1;
        if (nextTicketIndex > this.cases().length - 1) {
            nextTicketIndex = 0;
            this.shouldAutomaticallyForward = false;
            alert('All tickets have been checked. You will be redirected to the first ticket.');
        }

        this.router.navigateByUrl(`/${this.batchName()}/case-browser/${this.cases()[nextTicketIndex].id}`);
    }

    private goToPreviousAnchor() {
        this.viewChildIndex--;
        if (this.viewChildIndex < 0) {
            this.viewChildIndex = this.anchors.length - 1;
        }
        this.anchors.toArray()[this.viewChildIndex].nativeElement.scrollIntoView();
    }
    private goToNextAnchor() {
        this.viewChildIndex++;
        if (this.viewChildIndex > this.anchors.length - 1) {
            this.viewChildIndex = 0;
        }
        this.anchors.toArray()[this.viewChildIndex].nativeElement.scrollIntoView();
    }

    @HostListener('window:keydown', ['$event'])
    keyEvent(event: KeyboardEvent) {
        switch (event.key) {
            case 'ArrowLeft':
                this.goToPreviousTicket();
                break;
            case 'ArrowRight':
                this.goToNextTicket();
                break;
            case 'ArrowUp':
                this.goToPreviousAnchor();
                break;
            case 'ArrowDown':
                this.goToNextAnchor();
                break;
            case 'r':
                this.changeApprovalState(false);
                break;
            case 'a':
                this.changeApprovalState(true);
                this.goToNextTicket();
                break;
            case 'f':
                this.changeCustomerServiceFlag(!this.selectedCase()?.isFlaggedForReviewByCustomerService);
                break;
            case ' ':
                this.shouldAutomaticallyForward = !this.shouldAutomaticallyForward;
                break;
        }
    }

    async photoBikeTypeChanged(bikeType: BabboeCargoBikeIdentificationResult) {
        const batchName = this.batchName();
        const selectedCase = this.selectedCase();
        if (batchName && selectedCase) {
            this.cases()![this.selectedCaseIndex()!].photoIdentifiedBikeModel = bikeType;
            this.selectedCase()!.photoIdentifiedBikeModel = bikeType;
            await this.backendService.updateCase(batchName, selectedCase.id, {
                photoIdentifiedBikeModel: bikeType,
            });
        }
        this.goToNextTicket();
    }

    async invoiceBikeTypeChanged(bikeType: BabboeCargoBikeIdentificationResult) {
        const batchName = this.batchName();
        const selectedCase = this.selectedCase();
        if (batchName && selectedCase) {
            this.cases()![this.selectedCaseIndex()!].automatedInvoiceBikeType = bikeType;
            this.selectedCase()!.automatedInvoiceBikeType = bikeType;
            await this.backendService.updateCase(batchName, selectedCase.id, {
                invoiceBikeType: bikeType,
            });
        }
        this.goToNextTicket();
    }

    invoiceDateChanged(newDate: string) {
        const batchName = this.batchName();
        const selectedCase = this.selectedCase();
        if (batchName && selectedCase) {
            this.cases()![this.selectedCaseIndex()!].automatedInvoicePurchaseDate = newDate;
            this.selectedCase()!.automatedInvoicePurchaseDate = newDate;
            this.backendService.updateCase(batchName, selectedCase.id, {
                invoiceDate: newDate,
            });
        }
    }

    changeApprovalState(newState: boolean) {
        const batchName = this.batchName();
        const selectedCase = this.selectedCase();
        if (batchName && selectedCase) {
            this.cases()![this.selectedCaseIndex()!].isApproved = newState;
            this.selectedCase()!.isApproved = newState;
            this.backendService.updateCase(batchName, selectedCase.id, {
                isApproved: newState,
            });
        }
    }

    protected readonly BabboeCargoBikeIdentificationResult = BabboeCargoBikeIdentificationResult;

    changeCustomerServiceFlag(newState: boolean) {
        const batchName = this.batchName();
        const selectedCase = this.selectedCase();
        if (batchName && selectedCase) {
            this.cases()![this.selectedCaseIndex()!].isFlaggedForReviewByCustomerService = newState;
            this.selectedCase()!.isFlaggedForReviewByCustomerService = newState;
            this.backendService.updateCase(batchName, selectedCase.id, {
                isFlaggedForReviewByCustomerService: newState,
            });
        }
    }

    changeOverrulingFlag(newState: boolean) {
        const batchName = this.batchName();
        const selectedCase = this.selectedCase();
        if (batchName && selectedCase) {
            this.cases()![this.selectedCaseIndex()!].isFlaggedAsOverrulingCustomer = newState;
            this.selectedCase()!.isFlaggedAsOverrulingCustomer = newState;
            this.backendService.updateCase(batchName, selectedCase.id, {
                isFlaggedAsOverrulingCustomer: newState,
            });
        }
    }

    getBikeBestEstimate(bikeCase: BikeRecallCaseDto) {
        const customerModel = this.modelIdentificationResultService.fromCustomerModel(bikeCase.model);
        const photoBikeModel = this.modelIdentificationResultService.fromBikeType(
            bikeCase.photoIdentifiedBikeModel as never as BabboeCargoBikeTypes,
        );
        const invoiceModel = this.modelIdentificationResultService.fromBikeType(
            bikeCase.automatedInvoiceBikeType as never as BabboeCargoBikeTypes,
        );
        return this.bestEstimateService.getExpectedBikeType(customerModel, photoBikeModel, invoiceModel);
    }
    hasBikeModelDiscrepancy(bikeCase: BikeRecallCaseDto) {
        const customerModel = this.modelIdentificationResultService.fromCustomerModel(bikeCase.model);
        const photoBikeModel = this.modelIdentificationResultService.fromBikeType(
            bikeCase.photoIdentifiedBikeModel as never as BabboeCargoBikeTypes,
        );
        const invoiceModel = this.modelIdentificationResultService.fromBikeType(
            bikeCase.automatedInvoiceBikeType as never as BabboeCargoBikeTypes,
        );
        return this.bestEstimateService.flagModelDiscrepancy(customerModel, photoBikeModel, invoiceModel);
    }
    hasYearDiscrepancy(bikeCase: BikeRecallCaseDto) {
        const customerYear = bikeCase.modelYear;
        const bikeIdYear = this.bikeYearService.fromBikeId(bikeCase);
        const invoiceYear = this.bikeYearService.fromDate(bikeCase.automatedInvoicePurchaseDate);
        return this.bestEstimateService.flagYearDiscrepancy(customerYear, bikeIdYear, invoiceYear);
    }

    private doPhotoAndInvoiceMismatch(bikeRecallCaseDto: BikeRecallCaseDto) {
        if (
            bikeRecallCaseDto.photoIdentifiedBikeModel === BabboeCargoBikeIdentificationResult.Unknown ||
            bikeRecallCaseDto.automatedInvoiceBikeType === BabboeCargoBikeIdentificationResult.Unknown ||
            bikeRecallCaseDto.automatedInvoiceBikeType === undefined
        ) {
            return false;
        }
        if (bikeRecallCaseDto.photoIdentifiedBikeModel === bikeRecallCaseDto.automatedInvoiceBikeType) {
            return false;
        }
        return true;
    }

    private hasSingleEstimateThatMismatchesCustomer(bikeRecallCaseDto: BikeRecallCaseDto) {
        const customerModel = this.modelIdentificationResultService.fromCustomerModel(bikeRecallCaseDto.model);
        if (
            bikeRecallCaseDto.photoIdentifiedBikeModel === BabboeCargoBikeIdentificationResult.Unknown &&
            bikeRecallCaseDto.automatedInvoiceBikeType !== BabboeCargoBikeIdentificationResult.Unknown &&
            bikeRecallCaseDto.automatedInvoiceBikeType !== undefined
        ) {
            if (bikeRecallCaseDto.automatedInvoiceBikeType !== customerModel) {
                return true;
            }
        }
        if (
            bikeRecallCaseDto.photoIdentifiedBikeModel !== BabboeCargoBikeIdentificationResult.Unknown &&
            (bikeRecallCaseDto.automatedInvoiceBikeType === BabboeCargoBikeIdentificationResult.Unknown ||
                bikeRecallCaseDto.automatedInvoiceBikeType === undefined)
        ) {
            if (bikeRecallCaseDto.photoIdentifiedBikeModel !== customerModel) {
                return true;
            }
        }
        return false;
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    private autoForward() {
        // const cases = this.cases() ?? [];
        // const currentIndex = this.selectedCaseIndex() ?? 0;
        // const nextBikeIndex = (currentIndex + 1) % cases.length;
        // const nextBikeBestEstimate = this.getBikeBestEstimate(cases[nextBikeIndex]);
        // if (nextBikeBestEstimate !== this.currentBikeBestEstimate) {
        //     this.shouldAutomaticallyForward = false;
        // }
        // this.currentBikeBestEstimate = nextBikeBestEstimate;
        this.changeApprovalState(true);
        this.goToNextTicket();
    }
    async reasonForFlaggingChanged(reason: string) {
        const batchName = this.batchName();
        const selectedCase = this.selectedCase();
        if (batchName && selectedCase) {
            this.cases()![this.selectedCaseIndex()!].reasonForFlaggingForCustomerService = reason;
            this.selectedCase()!.reasonForFlaggingForCustomerService = reason;
            await this.backendService.updateCase(batchName, selectedCase.id, {
                reasonForFlaggingForCustomerService: reason,
            });
        }
    }
}
