<div class="container">
    <algemi-invoice-list
        class="case-list"
        [cases]="cases()"
        [selectedCaseId]="caseId()"
        [navigateSlice]="3"
        [showDaySwitcher]="true"
    ></algemi-invoice-list>

    <div class="content-container">
        @if(selectedCase()){
        <div #anchor></div>
        <h1 [ngClass]="{ exists: !!selectedCase()!.model, 'not-exists': !selectedCase()!.model }">
            Case ({{ getBikeBestEstimate(selectedCase()!) }}, {{ selectedCase()?.country }})
        </h1>
        <div class="identification-overview-container">
            <div class="identification-overview-column">
                <b>Source</b>
                <div>AI Model 1 (GPT-4)</div>
                <div>AI Model 2 (Claude 3 Opus)</div>
                <div>Human eval</div>
            </div>
            <div class="identification-overview-column">
                <b>Document Type</b>
                <div>{{ selectedCase()?.invoiceDocumentTypeModel1 ? selectedCase()?.invoiceDocumentTypeModel1 : '?' }}</div>
                <div>{{ selectedCase()?.invoiceDocumentTypeModel2 ? selectedCase()?.invoiceDocumentTypeModel2 : '?' }}</div>
                <div>-</div>
            </div>
            <div class="identification-overview-column">
                <b>Price</b>
                <div>
                    {{
                        selectedCase()?.invoiceBikePriceModel1
                            ? this.currencyToSymbolMap[this.selectedCase()?.currency ?? Currency.Eur] +
                              ' ' +
                              selectedCase()?.invoiceBikePriceModel1?.toFixed(2)
                            : '?'
                    }}
                </div>
                <div>
                    {{
                        selectedCase()?.invoiceBikePriceModel2
                            ? this.currencyToSymbolMap[this.selectedCase()?.currency ?? Currency.Eur] +
                              ' ' +
                              selectedCase()?.invoiceBikePriceModel2?.toFixed(2)
                            : '?'
                    }}
                </div>
                <div>
                    {{ getHumanPrice(selectedCase()) }}
                </div>
            </div>
            <div class="identification-overview-column">
                <b>Company</b>
                <div>
                    {{ selectedCase()?.invoiceCompanyModel1 }}
                </div>
                <div>
                    {{ selectedCase()?.invoiceCompanyModel2 }}
                </div>
                <div>-</div>
            </div>
        </div>
        <algemi-invoice-list
            class="case-list-summary"
            [cases]="[selectedCase()!]"
            [selectedCaseId]="selectedCase()!.id"
            [navigateSlice]="3"
        ></algemi-invoice-list>
        <algemi-button
            [disabled]="!priceBestEstimate(selectedCase())"
            (click)="priceChanged(priceBestEstimate(selectedCase()))"
            >{{ priceBestEstimateString(selectedCase()) }}</algemi-button
        >
        <algemi-button (click)="resetPrice()">Reset</algemi-button>

        <algemi-toggle
            [value]="this.selectedCase()!.isFlaggedAsSecondHand ?? false"
            [falseText]="'Flag as second hand'"
            [trueText]="'Flagged as second hand!'"
            (valueChanged)="changeSecondHandFlag($event)"
            >Flag as second hand</algemi-toggle
        >
        <div class="row-container">
            <algemi-text-input
                [value]="this.selectedCase()!.reasonForFlaggingForCustomerService ?? ''"
                (valueChange)="reasonForFlaggingForCustomerService = $event"
            ></algemi-text-input>
            <algemi-button (click)="reasonForFlaggingChanged(reasonForFlaggingForCustomerService)">Save feedback</algemi-button>
        </div>
        <algemi-toggle
            [value]="this.selectedCase()!.isFlaggedForReviewByCustomerService ?? false"
            [falseText]="'Flag for Customer Service'"
            [trueText]="'Flagged for Customer Service!'"
            (valueChanged)="changeCustomerServiceFlag($event)"
            >Flag for Customer Service</algemi-toggle
        >

        {{ selectedCase()?.invoiceType }}
        {{ getBikeBestEstimate(selectedCase()!) }}, {{ getYearBestEstimate(selectedCase()!) }}
        <div class="row-container">
            <algemi-ocr-image-component
                *ngIf="selectedCase()?.invoiceDataUrl"
                [src]="selectedCase()!.invoiceDataUrl"
                [ocrText]="selectedCase()!.invoiceRecognizedText"
                [type]="invoiceFileType()"
            ></algemi-ocr-image-component>
            <algemi-price-input
                (priceChanged)="priceChanged($event)"
                (accessoryPriceChanged)="accessoryPriceChanged($event)"
                (currencyChanged)="currencyChanged($event)"
                [priceModel1]="selectedCase()?.invoiceBikePriceModel1"
                [priceModel2]="selectedCase()?.invoiceBikePriceModel2"
                [vatPercentage]="selectedCase()?.invoiceBikeVatPercentage"
                [accessoryPrice]="selectedCase()?.accessoryPrice"
                [currency]="selectedCase()?.currency"
                [isRestOfWorld]="restOfWorldService.isRestOfWorld(selectedCase()?.country ?? Country.Netherlands)"
            ></algemi-price-input>
        </div>
        <algemi-purchase-date-input
            *ngIf="selectedCase()?.invoiceDataUrl"
            [dateInputValue]="selectedCase()?.automatedInvoicePurchaseDate"
            (dateChanged)="invoiceDateChanged($event)"
        ></algemi-purchase-date-input>
        } @else{
        <b>Please select a ticket</b>
        }
    </div>
</div>
