export class BikeYearService {
    fromBikeId(bikeCase: { bikeId: string | number; bikeIdDate?: string }): number | 'Unknown' {
        return typeof bikeCase.bikeId === 'string' && bikeCase.bikeId.startsWith('GB')
            ? this.fromDate(bikeCase.bikeIdDate)
            : 'Unknown';
    }

    fromDate(date: string | undefined): number | 'Unknown' {
        if (!date) {
            return 'Unknown';
        }
        const splitString = date.split('-');
        if (splitString.length !== 3 || !splitString[2]) {
            return 'Unknown';
        }
        return parseInt(splitString[2], 10);
    }
}
