<div class="price-container">
    <div class="row-container">
        <algemi-button (click)="confirmedPrice(priceModel1)" [disabled]="!priceModel1">{{
            formatPriceNumber(priceModel1)
        }}</algemi-button>
        <!--        <algemi-button (click)="confirmedPrice(priceModel2)" [disabled]="!priceModel2">{{-->
        <!--            formatPriceNumber(priceModel2)-->
        <!--        }}</algemi-button>-->
        <algemi-button
            (click)="confirmedPrice(priceModel1! * (1 + (vatPercentage ?? 0) / 100.0))"
            [disabled]="!priceModel1 || !vatPercentage"
            >{{ formatPriceNumber(priceModel1!) }} x {{ 1 + (vatPercentage ?? 0) / 100.0 }} =
            {{ formatPriceNumber(priceModel1! * (1 + (vatPercentage ?? 0) / 100.0)) }}</algemi-button
        >
    </div>
    <div class="row-container">
        <algemi-text-input [(value)]="priceValue"></algemi-text-input>
        <algemi-button (click)="confirmClicked()" [disabled]="!isValidPrice(priceValue)">{{
            formatPrice(priceValue)
        }}</algemi-button>
        <algemi-button
            (click)="confirmedPrice(this.toNumber(priceValue)! * (1 + (vatPercentage ?? 0) / 100.0))"
            [disabled]="!isValidPrice(priceValue) || !vatPercentage"
            >{{ formatPrice(priceValue!) }} x {{ 1 + (vatPercentage ?? 0) / 100.0 }} =
            {{ formatPriceNumber(this.toNumber(priceValue)! * (1 + (vatPercentage ?? 0) / 100.0)) }}</algemi-button
        >
    </div>
    <algemi-button (click)="this.priceChanged.emit('Unknown')">Unknown</algemi-button>
    <div class="currency row-container">
        @for (cur of allCurrencies;track allCurrencies){
        <algemi-button (click)="this.currencyChanged.emit(cur)" [ngClass]="{ selectedCurrency: cur === currency }">{{
            cur
        }}</algemi-button>
        }
    </div>
    @if(isRestOfWorld()){

    <algemi-accessory-price
        [currency]="currency"
        [accessoryPrice]="accessoryPrice()"
        (accessoryPriceChanged)="accessoryPriceChanged.emit($event)"
    ></algemi-accessory-price>
    }
</div>
