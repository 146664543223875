import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'algemi-change-day',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './change-day.component.html',
    styleUrl: './change-day.component.scss',
})
export class ChangeDayComponent {
    constructor(private router: Router) {}
    async goToPreviousDay() {
        await this.goToPageWithAddedDays(-1);
    }

    async goToNextDay() {
        await this.goToPageWithAddedDays(1);
    }

    private async goToPageWithAddedDays(numberOfDays: number) {
        const splitUrl = this.router.url.split('/');
        const newDateString = this.addDaysToDateString(splitUrl[1], numberOfDays);
        splitUrl[1] = newDateString;
        await this.router.navigateByUrl([splitUrl[0], newDateString, splitUrl[2]].join('/'));
    }

    addDaysToDateString(dateString: string, days: number) {
        const date = new Date(dateString);
        return new Date(date.setDate(date.getDate() + days)).toISOString().split('T')[0];
    }
}
