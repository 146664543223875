import { BabboeCargoBikeModels } from '../enums/babboe-cargo-bike-models';
import { BabboeCargoBikeTypes } from '../enums/babboe-cargo-bike-types';
import { BabboeBikeModel, Country } from './salesforce-bike-recall-case';
import { BabboeCargoBikeIdentificationResult } from '../enums/babboe-cargo-bike-identification-result';
import { Currency } from '../enums/currency';

export interface BikeRecallCaseDto {
    batchName: string;
    id: string;
    numberOfWheels: number;
    bikeId: string;
    electricBike: boolean;
    model: BabboeBikeModel;
    country: Country;
    modelYear: number;
    locationBattery: string;
    invoiceDataUrl?: string;
    invoiceType: 'pfd' | 'img' | undefined;
    bikeIdDate?: string;
    bikePhotoDataUrl?: string;
    bikePedalPhotoDataUrl?: string;
    isApproved?: boolean;
    isAdditionalDataFlowApproved?: Record<string, boolean>;
    isFlaggedForReviewByCustomerService?: boolean;
    isFlaggedAsOverrulingCustomer?: boolean;

    automatedIdentifiedBikeModel?: BabboeCargoBikeModels;
    automatedIsMountainModel?: boolean;

    automatedInvoicePurchaseDate?: string;
    automatedInvoiceBikeType?: BabboeCargoBikeIdentificationResult;
    photoIdentifiedBikeModel?: BabboeCargoBikeIdentificationResult;
    emailAttachmentIdentifiedBikeModel?: BabboeCargoBikeIdentificationResult;

    manuallyTaggedBestEstimateBikeType?: BabboeCargoBikeIdentificationResult;
    arePhotosValid?: boolean;
    humanIsMountainModel?: boolean;
    humanIdentifiedBikeModel?: BabboeCargoBikeModels;
    humanIdentifiedInvoiceBikeType?: BabboeCargoBikeTypes;
    humanIdentifiedInvoicePurchaseDate?: string;

    bikePhotoRecognizedText?: string;
    bikePedalPhotoRecognizedText?: string;
    invoiceRecognizedText?: string;

    invoiceDocumentTypeModel1?: 'invoice' | 'receipt' | 'transferOfOwnership' | 'leaseAgreement' | 'other';
    invoiceDocumentTypeModel2?: 'invoice' | 'receipt' | 'transferOfOwnership' | 'leaseAgreement' | 'other';
    invoiceBikePriceModel1?: number;
    invoiceBikePriceModel2?: number;
    invoiceBikeVatPercentage?: number;
    invoiceBikePriceHumanCheck?: number | 'Unknown';
    accessoryPrice?: number;

    invoiceCompanyModel1?: string;
    invoiceCompanyModel2?: string;

    isBikePhotoManuallyChecked?: boolean;
    isInvoiceBikeManuallyChecked?: boolean;
    currency?: Currency;
    isFlaggedAsSecondHand?: boolean;
    isFlaggedAsNotOriginalElectric?: boolean;
    isFlaggedAsUnusablePhotos?: boolean;
    reasonForFlaggingForCustomerService?: string;
    emailAttachments: string[];
    caseNumber?: string;
}
