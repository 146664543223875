import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BikeIdentificationCase } from '../../interfaces/bike-identification-case';
import { Router, RouterModule } from '@angular/router';
import {
    BabboeCargoBikeTypes,
    BestEstimateService,
    BikeRecallCaseDto,
    ModelIdentificationResultService,
} from '@algemi/accell/shared/babboe-bike-detection';

type CaseType = Partial<BikeIdentificationCase> & { id: string };

@Component({
    selector: 'algemi-photo-check-list',
    standalone: true,
    imports: [CommonModule, RouterModule],
    providers: [BestEstimateService, ModelIdentificationResultService],
    templateUrl: './case-photo-check-list.component.html',
    styleUrl: './case-photo-check-list.component.scss',
})
export class CasePhotoCheckListComponent {
    cases = input.required<BikeRecallCaseDto[]>();
    selectedCaseId = input<string>();
    navigateSlice = input<number>(4);
    constructor(
        private readonly router: Router,
        private readonly bestEstimateService: BestEstimateService,
        private readonly modelIdentificationResultService: ModelIdentificationResultService,
    ) {}

    navigate(ticketId: string) {
        const newUrl = [...this.router.url.split('/').slice(0, this.navigateSlice()), ticketId].join('/');
        this.router.navigateByUrl(newUrl);
    }

    getBikeBestEstimate(bikeCase: BikeRecallCaseDto) {
        const customerModel = this.modelIdentificationResultService.fromCustomerModel(bikeCase.model);
        const photoBikeModel = this.modelIdentificationResultService.fromBikeType(
            bikeCase.photoIdentifiedBikeModel as never as BabboeCargoBikeTypes,
        );
        const invoiceModel = this.modelIdentificationResultService.fromBikeType(
            bikeCase.automatedInvoiceBikeType as never as BabboeCargoBikeTypes,
        );
        return this.bestEstimateService.getExpectedBikeType(customerModel, photoBikeModel, invoiceModel);
    }

    isBestEstimateSameAsHuman(bikeCase: BikeRecallCaseDto) {
        // return (
        // this.doesBikeTypeMatchCustomerModel(bikeCase.photoIdentifiedBikeModel, bikeCase.model, true) &&
        // this.doesBikeTypeMatchCustomerModel(bikeCase.automatedInvoiceBikeType, bikeCase.model, true)
        // );
    }
}
