import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PdfViewerModule } from 'ng2-pdf-viewer';

@Component({
    selector: 'algemi-ocr-image-component',
    standalone: true,
    imports: [CommonModule, PdfViewerModule],
    templateUrl: './ocr-image.component.html',
    styleUrl: './ocr-image.component.scss',
})
export class OcrImageComponent {
    src = input<string>();
    ocrText = input<string>();
    type = input<'pdf' | 'img'>();
}
