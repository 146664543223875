import { Component, computed, EventEmitter, Input, input, model, OnChanges, Output, Signal, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextInputComponent } from '../text-input/text-input.component';
import { ButtonComponent } from '../button/button.component';
import { Currency, CurrencyConversionService } from '@algemi/accell/shared/babboe-bike-detection';

@Component({
    selector: 'algemi-accessory-price',
    standalone: true,
    imports: [CommonModule, TextInputComponent, ButtonComponent],
    providers: [CurrencyConversionService],
    templateUrl: './accessory-price.component.html',
    styleUrl: './accessory-price.component.scss',
})
export class AccessoryPriceComponent implements OnChanges {
    protected priceString = model<string>('');
    private priceValue: Signal<number>;
    protected priceValueInEur: Signal<number>;
    currency = input.required<Currency | undefined>();
    @Input() accessoryPrice: number | undefined;
    @Output() accessoryPriceChanged = new EventEmitter<number>();

    constructor(private readonly currencyConversionService: CurrencyConversionService) {
        this.priceValue = computed(() => {
            const price = parseFloat(this.priceString().replace(',', '.'));
            if (isNaN(price)) {
                return 0;
            }
            return parseFloat(price.toFixed(2));
        });

        this.priceValueInEur = computed(() => {
            return parseFloat((this.currencyConversionService.convertToEur(this.priceValue(), this.currency()) ?? 0).toFixed(2));
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.priceString.set(`${this.accessoryPrice ?? ''}`);
    }

    setAt200Eur() {
        const priceInEur = 200;
        const priceInCurrency = this.currencyConversionService.convertFromEur(priceInEur, this.currency()) ?? 0;
        this.priceString.set(priceInCurrency.toFixed(2));
        this.accessoryPriceChanged.emit(parseFloat(priceInCurrency.toFixed(2)));
    }

    reset() {
        this.priceString.set('');
        this.accessoryPriceChanged.emit(0);
    }

    protected readonly confirm = confirm;

    confirmPrice() {
        this.accessoryPriceChanged.emit(this.priceValue());
    }
}
