<div class="container">
    <algemi-photo-check-list
        class="case-list"
        [cases]="cases()"
        [selectedCaseId]="caseId()"
        [navigateSlice]="3"
    ></algemi-photo-check-list>

    <div class="content-container">
        @if(selectedCase()){
        <h1>{{ selectedCase()?.manuallyTaggedBestEstimateBikeType ?? getBikeBestEstimate(selectedCase()!) }}</h1>
        <algemi-photo-check-list
            class="case-list-summary"
            [cases]="[selectedCase()!]"
            [selectedCaseId]="selectedCase()!.id"
            [navigateSlice]="3"
        ></algemi-photo-check-list>
        <algemi-toggle
            [value]="this.selectedCase()!.arePhotosValid ?? false"
            [falseText]="'Tag as valid photo'"
            [trueText]="'Photos are valid!'"
            (valueChanged)="changePhotoValidityState($event)"
        ></algemi-toggle>
        <div class="image-container">
            <algemi-ocr-image-component
                [src]="selectedCase()!.bikePhotoDataUrl"
                [ocrText]="selectedCase()!.bikePhotoRecognizedText"
                type="img"
            ></algemi-ocr-image-component>
            <algemi-ocr-image-component
                [src]="selectedCase()!.bikePedalPhotoDataUrl"
                [ocrText]="selectedCase()!.bikePedalPhotoRecognizedText"
                type="img"
            ></algemi-ocr-image-component>
        </div>
        <algemi-bike-type-selection
            (typeClicked)="setManuallyTaggedBestEstimateBikeType($event)"
            [selectedType]="selectedCase()?.manuallyTaggedBestEstimateBikeType ?? getBikeBestEstimate(selectedCase()!)"
        ></algemi-bike-type-selection>

        <algemi-ocr-image-component
            *ngIf="selectedCase()?.invoiceDataUrl"
            [src]="selectedCase()!.invoiceDataUrl"
            [ocrText]="selectedCase()!.invoiceRecognizedText"
            [type]="invoiceFileType()"
        ></algemi-ocr-image-component>
        } @else{
        <b>Please select a ticket</b>
        }
    </div>
</div>
