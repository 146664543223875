export enum BabboeCargoBikeTypes {
    Big = 'Big',
    BigE = 'BigE',
    Curve = 'Curve',
    CurveE = 'CurveE',
    CurveMountain = 'CurveMountain',
    FlowE = 'FlowE',
    FlowMountain = 'FlowMountain',
    Mini = 'Mini',
    MiniE = 'MiniE',
    MiniMountain = 'MiniMountain',
    SlimMountain = 'SlimMountain',
    City = 'City',
    CityE = 'CityE',
    CityMountain = 'CityMountain',
    Go = 'Go',
    GoE = 'GoE',
    GoMountain = 'GoMountain',
    CarveE = 'CarveE',
    CarveMountain = 'CarveMountain',
    MaxE = 'MaxE',
    Dog = 'Dog',
    DogE = 'DogE',
    ProBike = 'ProBike',
    ProBikeE = 'ProBikeE',
    ProBikeMidMotor = 'ProBikeMidMotor',
    ProTrike = 'ProTrike',
    ProTrikeE = 'ProTrikeE',
    ProTrikeXlMidMotor = 'ProTrikeXlMidMotor',
    Transporter = 'Transporter',
    TransporterE = 'TransporterE',
}
