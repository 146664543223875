<div class="container">
    <div class="button-container" (click)="goToPreviousDay()">
        <div class="button-symbol"><</div>
        <div class="button-text">Previous day</div>
    </div>
    <div class="button-container" (click)="goToNextDay()">
        <div class="button-symbol">></div>
        <div class="button-text">Next day</div>
    </div>
    <div></div>
</div>
