export enum Currency {
    Eur = 'EUR',
    Gbp = 'GBP',
    Chf = 'CHF',
    Dkk = 'DKK',
    Nok = 'NOK',
    Sek = 'SEK',
    Cad = 'CAD',
    Usd = 'USD',
    Aud = 'AUD',
    Nzd = 'NZD',
    Pln = 'PLN',
}
