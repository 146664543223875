@for(bikeCase of cases();track bikeCase.id; let index = $index){
<div
    class="ticket-container"
    (click)="navigate(bikeCase.id)"
    [ngClass]="{
        selected: bikeCase.id === selectedCaseId(),
        analyzed: bikeCase.arePhotosValid === false,
        approved: bikeCase.arePhotosValid === true
    }"
>
    <b>{{ index + 1 }}</b
    >: {{ bikeCase.id }}
    <div class="flag-container">
        <div
            [ngClass]="{
                unavailable: !bikeCase.bikePhotoDataUrl,
                hasIdentifiedBike: bikeCase.photoIdentifiedBikeModel
            }"
        >
            BP
        </div>
        <div
            [ngClass]="{
                unavailable: !bikeCase.bikePedalPhotoDataUrl,
                hasIdentifiedBike: bikeCase.photoIdentifiedBikeModel
            }"
        >
            BPP
        </div>
        <div
            [ngClass]="{
                unavailable: !bikeCase.invoiceDataUrl,
                hasIdentifiedBike: bikeCase.automatedInvoiceBikeType
            }"
        >
            I
        </div>
        <div
            class="mask bike"
            [ngClass]="{
                unavailable: !(bikeCase.manuallyTaggedBestEstimateBikeType && bikeCase.manuallyTaggedBestEstimateBikeType!==getBikeBestEstimate(bikeCase)),
            }"
        ></div>
        <div
            class="mask flag"
            [ngClass]="{
                unavailable: !bikeCase.isFlaggedForReviewByCustomerService
            }"
        ></div>
    </div>
</div>
}
