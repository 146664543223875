export const validReasonsForFlagging = [
    'German lease bike - for evaluation by German team',
    'Voor gerard - fiets in 2020 omgebouwd naar electrisch',
    'No invoice, but good to contact customer as they left a note',
    'Repairs invoices, original invoice missing',
    'Second hand marktplaats bike',
    'Invoice is not for a bike - accessories only',
    'This is an invoice for 5 bikes',
    'Not the original invoice',
    'Invoice date is after start of recall - maybe re-issued',
    'There are no bike photos',
    'Photos that have been uploaded are not of a bike',
    'Photo is taken from marketing material',
    'Does not seem to be original electric bike',
    'Unsure whether it is an original electric bike',
    'Mismatch between photo and invoice bike identification',
    'Invoice is provided as bike photo',
    'Duplicated case',
    'Electric bike from Switzerland',
];
