@if (showDaySwitcher()){
<algemi-change-day></algemi-change-day>
} @for(bikeCase of cases();track bikeCase.id; let index = $index){
<div
    class="ticket-container"
    (click)="navigate(bikeCase.id)"
    [ngClass]="{
        selected: bikeCase.id === selectedCaseId(),
        analyzed: wasAnalysisPerformed(bikeCase),
        doPhotoAndInvoiceMismatch: doPhotoAndInvoiceMismatch(bikeCase),
        hasSingleEstimateThatMismatchesCustomer: hasSingleEstimateThatMismatchesCustomer(bikeCase),
        unidentifiedBike: getBikeBestEstimate(bikeCase) === BabboeCargoBikeIdentificationResult.Unknown,
        isBikeIdentifiedUsingEmail: !!bikeCase.emailAttachmentIdentifiedBikeModel,
        hasNoAttachments: (bikeCase.emailAttachments ?? []).length === 0,
        approved: whereAllQualityChecksComplete(bikeCase)
    }"
>
    <b>{{ index + 1 }}</b
    >: {{ bikeCase.id }}
    <div class="flag-container">
        <div *ngIf="isAdditionalDataFlowApproved(bikeCase)" class="mask check"></div>
        <div
            [ngClass]="{
                unavailable: !bikeCase.bikePhotoDataUrl,
                hasIdentifiedBike: bikeCase.photoIdentifiedBikeModel
            }"
        >
            BP
        </div>
        <div
            [ngClass]="{
                unavailable: !bikeCase.bikePedalPhotoDataUrl,
                hasIdentifiedBike: bikeCase.photoIdentifiedBikeModel
            }"
        >
            BPP
        </div>
        <div
            [ngClass]="{
                unavailable: !bikeCase.invoiceDataUrl,
                hasIdentifiedBike: bikeCase.automatedInvoiceBikeType
            }"
        >
            I
        </div>
        <div
            class="mask bike"
            [ngClass]="{
                unavailable:
                    bikeCase.emailAttachmentIdentifiedBikeModel !== undefined &&
                    bikeCase.emailAttachmentIdentifiedBikeModel !== 'Unknown'
            }"
        ></div>
        <div
            class="mask customer"
            [ngClass]="{
                unavailable: getIsBikeModelSameAsCustomer(bikeCase)
            }"
        ></div>
        <div
            class="mask nonElectric"
            [ngClass]="{
                unavailable: !bikeCase.isFlaggedAsNotOriginalElectric
            }"
        ></div>
        <div
            class="mask unusablePhotos"
            [ngClass]="{
                unavailable: !bikeCase.isFlaggedAsUnusablePhotos
            }"
        ></div>
        <div
            class="mask flag"
            [ngClass]="{
                unavailable: !isFlagged(bikeCase)
            }"
        ></div>
    </div>
</div>
}
