import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'algemi-filter-rest-of-world',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './filter-rest-of-world.component.html',
    styleUrl: './filter-rest-of-world.component.scss',
})
export class FilterRestOfWorldComponent implements OnInit {
    isFilteringRestOfWorldState = false;

    constructor(private readonly router: Router, private readonly activatedRoute: ActivatedRoute) {}

    ngOnInit() {
        this.isFilteringRestOfWorldState = this.activatedRoute.snapshot.queryParams['filterRestOfWorld'] === 'true';
    }

    async toggleFilter() {
        await this.router.navigate([], {
            queryParams: {
                filterRestOfWorld: !this.isFilteringRestOfWorldState,
            },
            queryParamsHandling: 'merge',
        });
        this.isFilteringRestOfWorldState = !this.isFilteringRestOfWorldState;
    }
}
