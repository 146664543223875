import {
    Component,
    computed,
    effect,
    ElementRef,
    HostListener,
    QueryList,
    signal,
    Signal,
    ViewChildren,
    WritableSignal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs';
import { BackendService } from '../../services/backend.service';
import { ButtonComponent } from '../button/button.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import {
    BabboeCargoBikeIdentificationResult,
    BabboeCargoBikeTypes,
    BestEstimateService,
    BikeRecallCaseDto,
    BikeYearService,
    Country,
    Currency,
    InvoiceAnalysisFilterService,
    ModelIdentificationResultService,
    RestOfWorldService,
} from '@algemi/accell/shared/babboe-bike-detection';
import { OcrImageComponent } from '../ocr-image-component/ocr-image.component';
import { CaseListComponent } from '../case-list/case-list.component';
import { BikeTypeSelectionComponent } from '../bike-type-selection/bike-type-selection.component';
import { PurchaseDateInputComponent } from '../purchase-date-input/purchase-date-input.component';
import { ToggleComponent } from '../toggle/toggle.component';
import { InvoiceListComponent } from '../invoice-list/invoice-list.component';
import { PriceInputComponent } from '../price-input/price-input.component';
import { TextInputComponent } from '../text-input/text-input.component';

@Component({
    selector: 'algemi-invoice-browser-page',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        ButtonComponent,
        PdfViewerModule,
        OcrImageComponent,
        CaseListComponent,
        BikeTypeSelectionComponent,
        PurchaseDateInputComponent,
        ToggleComponent,
        InvoiceListComponent,
        PriceInputComponent,
        TextInputComponent,
    ],
    providers: [
        BestEstimateService,
        ModelIdentificationResultService,
        BikeYearService,
        {
            provide: InvoiceAnalysisFilterService,
            useFactory: () => {
                return new InvoiceAnalysisFilterService(
                    new BestEstimateService(),
                    new ModelIdentificationResultService(),
                    new BikeYearService(),
                    new RestOfWorldService(),
                );
            },
        },
        RestOfWorldService,
    ],
    templateUrl: './invoice-browser-page.component.html',
    styleUrl: './invoice-browser-page.component.scss',
})
export class InvoiceBrowserPageComponent {
    protected batchName: Signal<string | undefined>;
    protected caseId: Signal<string | undefined>;
    protected cases: WritableSignal<BikeRecallCaseDto[]>;
    protected selectedCaseIndex: Signal<number | undefined>;
    protected selectedCase: WritableSignal<BikeRecallCaseDto | undefined>;
    protected invoiceFileType: Signal<'pdf' | 'img' | undefined> = signal(undefined);
    private readonly allCases = signal<BikeRecallCaseDto[]>([]);
    private readonly filterRestOfWorldState: Signal<boolean | undefined>;
    @ViewChildren('anchor') anchors!: QueryList<ElementRef<HTMLElement>>;
    viewChildIndex = 0;
    protected reasonForFlaggingForCustomerService = '';

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        protected readonly backendService: BackendService,
        private readonly router: Router,
        private readonly bestEstimateService: BestEstimateService,
        private readonly modelIdentificationResultService: ModelIdentificationResultService,
        private readonly bikeYearService: BikeYearService,
        private readonly invoiceAnalysisFilterService: InvoiceAnalysisFilterService,
        protected readonly restOfWorldService: RestOfWorldService,
    ) {
        this.batchName = toSignal(this.activatedRoute.params.pipe(map((p) => p['batchName'])));
        this.caseId = toSignal(this.activatedRoute.params.pipe(map((p) => p['ticketId'])));
        this.filterRestOfWorldState = toSignal(
            this.activatedRoute.queryParams.pipe(map((p) => p['filterRestOfWorld'] === 'true')),
        );

        this.selectedCaseIndex = computed(() => this.cases().findIndex((t) => t.id === this.caseId()));

        this.cases = signal<BikeRecallCaseDto[]>([]);
        effect(() => {
            const batchName = this.batchName();
            if (batchName) {
                this.backendService.getBikeCases(batchName).then((allCases) => this.allCases.set(allCases));
            }
        });
        effect(
            async () => {
                return this.cases.set(
                    this.filterCasesToCheckInvoice(
                        this.allCases().filter((c) => {
                            if (this.filterRestOfWorldState() === true) {
                                return this.restOfWorldService.isRestOfWorld(c.country);
                            } else {
                                return true;
                            }
                        }),
                    ),
                );
            },
            { allowSignalWrites: true },
        );
        this.selectedCase = signal<BikeRecallCaseDto | undefined>(undefined);
        effect(
            () => {
                const batchName = this.batchName();
                const caseId = this.caseId();
                if (batchName && caseId && (this.selectedCaseIndex() ?? -1) >= 0) {
                    this.backendService.getCase(batchName, caseId).then((c) => this.selectedCase.set(c));
                }
                this.selectedCase.set(undefined);
                this.viewChildIndex = 0;
            },
            { allowSignalWrites: true },
        );
        this.invoiceFileType = computed(() => {
            const bikeCase = this.selectedCase();
            return bikeCase?.invoiceType as never;
        });
    }

    protected currencyToSymbolMap = {
        [Currency.Eur]: '€',
        [Currency.Chf]: 'CHF',
        [Currency.Gbp]: '£',
        [Currency.Dkk]: 'DKK',
        [Currency.Sek]: 'SEK',
        [Currency.Nok]: 'NOK',
        [Currency.Cad]: 'CAD',
        [Currency.Usd]: '$',
        [Currency.Aud]: 'AUD',
        [Currency.Nzd]: 'NZD',
        [Currency.Pln]: 'PLN',
    };

    private filterCasesToCheckInvoice(allCases: BikeRecallCaseDto[]) {
        return (
            allCases
                .filter((c) =>
                    this.invoiceAnalysisFilterService.doesCaseInvoiceNeedToBeAnalyzedForPrice({
                        bikeId: c.bikeId,
                        customerYear: c.modelYear,
                        automatedInvoiceBikeType: c.automatedInvoiceBikeType as BabboeCargoBikeIdentificationResult,
                        automatedInvoicePurchaseDate: c.automatedInvoicePurchaseDate,
                        bikeIdDate: c.bikeIdDate,
                        invoiceFileType: c.invoiceType,
                        photoIdentifiedBikeModel: c.photoIdentifiedBikeModel,
                        customerModel: c.model,
                        country: c.country,
                    }),
                )
                // .map((c) => ({ ...c, sortProperty: `${c.invoiceCompanyModel1} - ${c.invoiceCompanyModel2}` }))
                .map((c) => ({ ...c, sortProperty: `${c.isFlaggedAsSecondHand} - ${c.invoiceCompanyModel1}` }))
                .sort((a, b) => (a.sortProperty < b.sortProperty ? -1 : 1))
        );
    }

    private goToPreviousTicket() {
        this.viewChildIndex = 0;
        let previousTicketIndex = (this.selectedCaseIndex() ?? -1) - 1;
        if (previousTicketIndex < 0) {
            previousTicketIndex = this.cases().length - 1;
        }
        this.router.navigateByUrl(
            `/${this.batchName()}/invoice-browser/${this.cases()[previousTicketIndex].id}?filterRestOfWorld=${
                this.activatedRoute.snapshot.queryParams['filterRestOfWorld'] ?? false
            }`,
        );
    }

    private goToNextTicket() {
        this.viewChildIndex = 0;
        let nextTicketIndex = (this.selectedCaseIndex() ?? -1) + 1;
        if (nextTicketIndex > this.cases().length - 1) {
            nextTicketIndex = 0;
            alert('All tickets have been checked. You will be redirected to the first ticket.');
        }

        this.router.navigateByUrl(
            `/${this.batchName()}/invoice-browser/${this.cases()[nextTicketIndex].id}?filterRestOfWorld=${
                this.activatedRoute.snapshot.queryParams['filterRestOfWorld'] ?? false
            }`,
        );
    }

    private goToPreviousAnchor() {
        this.viewChildIndex--;
        if (this.viewChildIndex < 0) {
            this.viewChildIndex = this.anchors.length - 1;
        }
        this.anchors.toArray()[this.viewChildIndex].nativeElement.scrollIntoView();
    }
    private goToNextAnchor() {
        this.viewChildIndex++;
        if (this.viewChildIndex > this.anchors.length - 1) {
            this.viewChildIndex = 0;
        }
        this.anchors.toArray()[this.viewChildIndex].nativeElement.scrollIntoView();
    }

    @HostListener('window:keydown', ['$event'])
    keyEvent(event: KeyboardEvent) {
        let priceBestEstimate: number | undefined | 'Unknown';

        const batchName = this.batchName();
        const selectedCase = this.selectedCase();
        switch (event.key) {
            case 'ArrowLeft':
                this.goToPreviousTicket();
                break;
            case 'ArrowRight':
                this.goToNextTicket();
                break;
            case 'u':
                this.priceChanged('Unknown');
                break;
            case 'Enter':
                priceBestEstimate = this.priceBestEstimate(this.selectedCase());
                if (batchName && selectedCase && priceBestEstimate) {
                    this.priceChanged(priceBestEstimate);
                    this.goToNextTicket();
                }
                break;
        }
    }

    async photoBikeTypeChanged(bikeType: BabboeCargoBikeIdentificationResult) {
        const batchName = this.batchName();
        const selectedCase = this.selectedCase();
        if (batchName && selectedCase) {
            this.cases()![this.selectedCaseIndex()!].photoIdentifiedBikeModel = bikeType;
            this.selectedCase()!.photoIdentifiedBikeModel = bikeType;
            await this.backendService.updateCase(batchName, selectedCase.id, {
                photoIdentifiedBikeModel: bikeType,
            });
        }
    }

    async invoiceBikeTypeChanged(bikeType: BabboeCargoBikeIdentificationResult) {
        const batchName = this.batchName();
        const selectedCase = this.selectedCase();
        if (batchName && selectedCase) {
            this.cases()![this.selectedCaseIndex()!].automatedInvoiceBikeType = bikeType;
            this.selectedCase()!.automatedInvoiceBikeType = bikeType;
            await this.backendService.updateCase(batchName, selectedCase.id, {
                invoiceBikeType: bikeType,
            });
        }
    }

    invoiceDateChanged(newDate: string) {
        const batchName = this.batchName();
        const selectedCase = this.selectedCase();
        if (batchName && selectedCase) {
            this.cases()![this.selectedCaseIndex()!].automatedInvoicePurchaseDate = newDate;
            this.selectedCase()!.automatedInvoicePurchaseDate = newDate;
            this.backendService.updateCase(batchName, selectedCase.id, {
                invoiceDate: newDate,
            });
        }
    }

    changeApprovalState(newState: boolean) {
        const batchName = this.batchName();
        const selectedCase = this.selectedCase();
        if (batchName && selectedCase) {
            this.cases()![this.selectedCaseIndex()!].isApproved = newState;
            this.selectedCase()!.isApproved = newState;
            this.backendService.updateCase(batchName, selectedCase.id, {
                isApproved: newState,
            });
        }
    }

    protected readonly BabboeCargoBikeIdentificationResult = BabboeCargoBikeIdentificationResult;

    changeCustomerServiceFlag(newState: boolean) {
        const batchName = this.batchName();
        const selectedCase = this.selectedCase();
        if (batchName && selectedCase) {
            this.cases()![this.selectedCaseIndex()!].isFlaggedForReviewByCustomerService = newState;
            this.selectedCase()!.isFlaggedForReviewByCustomerService = newState;
            this.backendService.updateCase(batchName, selectedCase.id, {
                isFlaggedForReviewByCustomerService: newState,
            });
        }
    }
    changeSecondHandFlag(newState: boolean) {
        const batchName = this.batchName();
        const selectedCase = this.selectedCase();
        if (batchName && selectedCase) {
            this.cases()![this.selectedCaseIndex()!].isFlaggedAsSecondHand = newState;
            this.selectedCase()!.isFlaggedAsSecondHand = newState;
            this.backendService.updateCase(batchName, selectedCase.id, {
                isFlaggedAsSecondHand: newState,
            });
        }
    }

    changeOverrulingFlag(newState: boolean) {
        const batchName = this.batchName();
        const selectedCase = this.selectedCase();
        if (batchName && selectedCase) {
            this.cases()![this.selectedCaseIndex()!].isFlaggedAsOverrulingCustomer = newState;
            this.selectedCase()!.isFlaggedAsOverrulingCustomer = newState;
            this.backendService.updateCase(batchName, selectedCase.id, {
                isFlaggedAsOverrulingCustomer: newState,
            });
        }
    }

    getBikeBestEstimate(bikeCase: BikeRecallCaseDto) {
        const customerModel = this.modelIdentificationResultService.fromCustomerModel(bikeCase.model);
        const photoBikeModel = this.modelIdentificationResultService.fromBikeType(
            bikeCase.photoIdentifiedBikeModel as never as BabboeCargoBikeTypes,
        );
        const invoiceModel = this.modelIdentificationResultService.fromBikeType(
            bikeCase.automatedInvoiceBikeType as never as BabboeCargoBikeTypes,
        );
        return this.bestEstimateService.getExpectedBikeType(customerModel, photoBikeModel, invoiceModel);
    }

    protected getYearBestEstimate(bikeCase: BikeRecallCaseDto) {
        const customerYear = bikeCase.modelYear;
        const bikeIdYear = this.bikeYearService.fromBikeId(bikeCase);
        const invoiceYear = this.bikeYearService.fromDate(bikeCase.automatedInvoicePurchaseDate);
        return this.bestEstimateService.getExpectedYear(customerYear, bikeIdYear, invoiceYear);
    }

    priceBestEstimate(bikeCase: BikeRecallCaseDto | undefined) {
        if (!bikeCase) {
            return undefined;
        }
        const model1Price = bikeCase.invoiceBikePriceModel1;
        const model2Price = bikeCase.invoiceBikePriceModel2;
        const humanCheckPrice = bikeCase.invoiceBikePriceHumanCheck;
        return this.bestEstimateService.getExpectedPrice(model1Price, model2Price, humanCheckPrice);
    }

    priceBestEstimateString(bikeCase: BikeRecallCaseDto | undefined): string {
        const bestEstimate = this.priceBestEstimate(bikeCase);

        if (!bestEstimate || bestEstimate === 'Unknown') {
            return 'Unknown';
        }

        return `Confirm ${this.currencyToSymbolMap[this.selectedCase()?.currency ?? Currency.Eur]} ${bestEstimate.toFixed(2)}`;
    }

    getHumanPrice(bikeCase: BikeRecallCaseDto | undefined) {
        return bikeCase?.invoiceBikePriceHumanCheck
            ? bikeCase.invoiceBikePriceHumanCheck !== 'Unknown'
                ? `${
                      this.currencyToSymbolMap[this.selectedCase()?.currency ?? Currency.Eur]
                  } ${bikeCase.invoiceBikePriceHumanCheck?.toFixed(2)}`
                : 'Unknown'
            : '-';
    }

    async priceChanged(price: number | undefined | 'Unknown') {
        if (!price) {
            return;
        }
        const batchName = this.batchName();
        const selectedCase = this.selectedCase();
        if (batchName && selectedCase) {
            this.cases()![this.selectedCaseIndex()!].invoiceBikePriceHumanCheck = price;
            this.selectedCase()!.invoiceBikePriceHumanCheck = price;
            await this.backendService.updateCase(batchName, selectedCase.id, {
                invoiceBikePriceHumanCheck: price,
            });
        }
        // this.goToNextTicket();
    }

    async accessoryPriceChanged(accessoryPrice: number) {
        const batchName = this.batchName();
        const selectedCase = this.selectedCase();
        if (batchName && selectedCase) {
            this.cases()![this.selectedCaseIndex()!].accessoryPrice = accessoryPrice;
            this.selectedCase()!.accessoryPrice = accessoryPrice;
            await this.backendService.updateCase(batchName, selectedCase.id, {
                accessoryPrice: accessoryPrice,
            });
        }
    }

    async resetPrice() {
        const batchName = this.batchName();
        const selectedCase = this.selectedCase();
        if (batchName && selectedCase) {
            this.cases()![this.selectedCaseIndex()!].invoiceBikePriceHumanCheck = null as never as undefined;
            this.selectedCase()!.invoiceBikePriceHumanCheck = null as never as undefined;
            await this.backendService.updateCase(batchName, selectedCase.id, {
                invoiceBikePriceHumanCheck: null as never as undefined,
            });
        }
    }

    async currencyChanged(currency: Currency) {
        if (!currency) {
            return;
        }
        const batchName = this.batchName();
        const selectedCase = this.selectedCase();
        if (batchName && selectedCase) {
            this.cases()![this.selectedCaseIndex()!].currency = currency;
            this.selectedCase()!.currency = currency;
            await this.backendService.updateCase(batchName, selectedCase.id, {
                currency: currency,
            });
        }
    }

    protected readonly Currency = Currency;

    async reasonForFlaggingChanged(reason: string) {
        const batchName = this.batchName();
        const selectedCase = this.selectedCase();
        if (batchName && selectedCase) {
            this.cases()![this.selectedCaseIndex()!].reasonForFlaggingForCustomerService = reason;
            this.selectedCase()!.reasonForFlaggingForCustomerService = reason;
            await this.backendService.updateCase(batchName, selectedCase.id, {
                reasonForFlaggingForCustomerService: reason,
            });
        }
    }

    protected readonly Country = Country;
}
