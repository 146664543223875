import {
    Component,
    computed,
    effect,
    ElementRef,
    HostListener,
    QueryList,
    signal,
    Signal,
    ViewChildren,
    WritableSignal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs';
import { BackendService } from '../../services/backend.service';
import { ButtonComponent } from '../button/button.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import {
    BabboeCargoBikeIdentificationResult,
    BabboeCargoBikeTypes,
    BestEstimateService,
    BikeRecallCaseDto,
    ModelIdentificationResultService,
} from '@algemi/accell/shared/babboe-bike-detection';
import { OcrImageComponent } from '../ocr-image-component/ocr-image.component';
import { CaseListComponent } from '../case-list/case-list.component';
import { BikeTypeSelectionComponent } from '../bike-type-selection/bike-type-selection.component';
import { PurchaseDateInputComponent } from '../purchase-date-input/purchase-date-input.component';
import { ToggleComponent } from '../toggle/toggle.component';
import { CasePhotoCheckListComponent } from '../case-photo-check-list/case-photo-check-list.component';

@Component({
    selector: 'algemi-case-photo-checker-page',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        ButtonComponent,
        PdfViewerModule,
        OcrImageComponent,
        CaseListComponent,
        BikeTypeSelectionComponent,
        PurchaseDateInputComponent,
        ToggleComponent,
        CasePhotoCheckListComponent,
    ],
    providers: [BestEstimateService, ModelIdentificationResultService],
    templateUrl: './case-photo-checker-page.component.html',
    styleUrl: './case-photo-checker-page.component.scss',
})
export class CasePhotoCheckerPageComponent {
    protected batchName: Signal<string | undefined>;
    protected caseId: Signal<string | undefined>;
    protected cases: WritableSignal<BikeRecallCaseDto[]>;
    protected selectedCaseIndex: Signal<number | undefined>;
    protected selectedCase: WritableSignal<BikeRecallCaseDto | undefined>;
    protected invoiceFileType: Signal<'pdf' | 'img' | undefined> = signal(undefined);
    @ViewChildren('anchor') anchors!: QueryList<ElementRef<HTMLElement>>;
    viewChildIndex = 0;

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        protected readonly backendService: BackendService,
        private readonly router: Router,
        private readonly bestEstimateService: BestEstimateService,
        private readonly modelIdentificationResultService: ModelIdentificationResultService,
    ) {
        this.batchName = toSignal(this.activatedRoute.params.pipe(map((p) => p['batchName'])));
        this.caseId = toSignal(this.activatedRoute.params.pipe(map((p) => p['ticketId'])));

        this.selectedCaseIndex = computed(() => this.cases().findIndex((t) => t.id === this.caseId()));

        this.cases = signal<BikeRecallCaseDto[]>([]);
        effect(
            async () => {
                const batchName = this.batchName();
                return batchName ? this.cases.set(await this.backendService.getBikeCases(batchName)) : [];
            },
            { allowSignalWrites: true },
        );
        this.selectedCase = signal<BikeRecallCaseDto | undefined>(undefined);
        effect(
            () => {
                const batchName = this.batchName();
                const caseId = this.caseId();
                if (batchName && caseId && (this.selectedCaseIndex() ?? -1) >= 0) {
                    this.backendService.getCase(batchName, caseId).then((c) => this.selectedCase.set(c));
                }
                this.selectedCase.set(undefined);
                this.viewChildIndex = 0;
            },
            { allowSignalWrites: true },
        );
        this.invoiceFileType = computed(() => {
            const bikeCase = this.selectedCase();
            return bikeCase?.invoiceType as never;
        });
    }

    private goToPreviousTicket() {
        this.viewChildIndex = 0;
        let previousTicketIndex = (this.selectedCaseIndex() ?? -1) - 1;
        if (previousTicketIndex < 0) {
            previousTicketIndex = this.cases().length - 1;
        }
        this.router.navigateByUrl(`/${this.batchName()}/case-photo-checker/${this.cases()[previousTicketIndex].id}`);
    }

    private goToNextTicket() {
        this.viewChildIndex = 0;
        let nextTicketIndex = (this.selectedCaseIndex() ?? -1) + 1;
        if (nextTicketIndex > this.cases().length - 1) {
            nextTicketIndex = 0;
        }

        this.router.navigateByUrl(`/${this.batchName()}/case-photo-checker/${this.cases()[nextTicketIndex].id}`);
    }

    @HostListener('window:keydown', ['$event'])
    keyEvent(event: KeyboardEvent) {
        switch (event.key) {
            case 'ArrowLeft':
                this.goToPreviousTicket();
                break;
            case 'ArrowRight':
                this.goToNextTicket();
                break;
            case '/':
                if (this.selectedCase()?.arePhotosValid === undefined) {
                    this.changePhotoValidityState(false);
                } else {
                    this.changePhotoValidityState(!this.selectedCase()?.arePhotosValid);
                }
                break;
            case 'Enter':
                if (this.selectedCase()?.arePhotosValid === undefined) {
                    this.changePhotoValidityState(true);
                }
                this.goToNextTicket();
                break;
        }
    }

    async setManuallyTaggedBestEstimateBikeType(bikeType: BabboeCargoBikeIdentificationResult) {
        const batchName = this.batchName();
        const selectedCase = this.selectedCase();
        if (batchName && selectedCase) {
            this.cases()![this.selectedCaseIndex()!].manuallyTaggedBestEstimateBikeType = bikeType;
            this.selectedCase()!.manuallyTaggedBestEstimateBikeType = bikeType;
            await this.backendService.updateCase(batchName, selectedCase.id, {
                manuallyTaggedBestEstimateBikeType: bikeType,
            });
        }
    }

    changePhotoValidityState(newState: boolean) {
        const batchName = this.batchName();
        const selectedCase = this.selectedCase();
        if (batchName && selectedCase) {
            this.cases()![this.selectedCaseIndex()!].arePhotosValid = newState;
            this.selectedCase()!.arePhotosValid = newState;
            this.backendService.updateCase(batchName, selectedCase.id, {
                arePhotosValid: newState,
            });
        }
    }

    protected readonly BabboeCargoBikeIdentificationResult = BabboeCargoBikeIdentificationResult;

    getBikeBestEstimate(bikeCase: BikeRecallCaseDto) {
        const customerModel = this.modelIdentificationResultService.fromCustomerModel(bikeCase.model);
        const photoBikeModel = this.modelIdentificationResultService.fromBikeType(
            bikeCase.photoIdentifiedBikeModel as never as BabboeCargoBikeTypes,
        );
        const invoiceModel = this.modelIdentificationResultService.fromBikeType(
            bikeCase.automatedInvoiceBikeType as never as BabboeCargoBikeTypes,
        );
        return this.bestEstimateService.getExpectedBikeType(customerModel, photoBikeModel, invoiceModel);
    }
}
