<h3>Accessory price</h3>
<algemi-text-input [(value)]="priceString"></algemi-text-input>
<p>
    Equivalent to: <b>€{{ priceValueInEur() ?? '' }}</b>
</p>
<div class="button-container">
    <algemi-button (click)="confirmPrice()">Confirm</algemi-button>
    <algemi-button (click)="setAt200Eur()">Set at €200</algemi-button>
    <algemi-button (click)="reset()">Reset</algemi-button>
</div>
